import React from 'react';
import { Row, Label,Col,CustomInput,FormGroup } from 'reactstrap';
import {DefaultInput, DefaultButton} from '../../UI/atoms/index';
// import {withRouter} from "react-router";


 class SignUpSteptwo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

  render(){
      const { step2, checkValidationStep2, categoryList, onChangeCheckEvent,formData, formData : {new_alignmentwithmission, new_agencybenefits, cr676_events_tickets_interests,
        new_ticketsfromotherorganizations, new_liabilityinsurancelevel, cr676_children_serve_0_6, cr676_children_serve_7_12, cr676_children_serve_13_18, bsi_numberofchildren,
          cr676_short_tickets_tat, cr676_short_tickets_tat_description, cr676_pickup_tickets_from_hq, cr676_pickup_tickets_from_hq_description}, validationStep2: {new_ticketsfromotherorganizationsV, pickup_ticketV, short_ticketV, children_serve_6V, children_serve_7V, children_serve_13V, liabilityV, 
            new_alignmentwithmissionV, new_agencybenefitsV, interestsCheckBoxV}, onChangeEvent } = this.props;
    return(

      <React.Fragment>

       <FormGroup>  
         <Label>Describe the services your organization provides that align with Kids Up Front’s mission of “providing access to arts, culture, sports, and recreation for kids that need the opportunity.” <span style={{ color: "red" }}>*</span></Label>
        <Row>
          <Col md="12">
            <DefaultInput
              name="new_alignmentwithmission"  //description
              value={new_alignmentwithmission}   //description
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              type="text"
              validation={new_alignmentwithmissionV}  //opportunityV
            />
          </Col>
          
        </Row>
      </FormGroup>

      {/*<FormGroup>  */}
      {/*  <Label>Which of the following best describe the demographics your agency serves? Select All that apply:</Label>*/}
      {/*  <Row>*/}
      {/*    <Col md="4">*/}
      {/*        <select className="form-control">*/}
      {/*          <option>Others</option>*/}
      {/*          <option>option</option>*/}
      {/*          <option>option</option>*/}
      {/*          <option>option</option>*/}
      {/*        </select>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</FormGroup> */}

      {/*<FormGroup>  */}
      {/*  <Label>Which best describes your agency and/or programs. Select all that apply:</Label>*/}
      {/*  <Row>*/}
      {/*    <Col md="4">*/}
      {/*        <select className="form-control">*/}
      {/*          <option>Others</option>*/}
      {/*          <option>option</option>*/}
      {/*          <option>option</option>*/}
      {/*          <option>option</option>*/}
      {/*        </select>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</FormGroup> */}


      <FormGroup>  
         <Label>Please describe the added value or benefits your organization would receive with access to Kids Up Front event tickets: <span style={{ color: "red" }}>*</span></Label>
        <Row>
          <Col md="12">
            <DefaultInput
              name="new_agencybenefits"  //cr676_addedvaluebenefits
              value={new_agencybenefits} //cr676_addedvaluebenefits
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              type="text"
              validation={new_agencybenefitsV}  //cr676_addedvaluebenefitsV
            />
          </Col>
        </Row>
      </FormGroup>


      <FormGroup>  
         <Label>Event/Ticket Interests (check all that apply) <span style={{ color: "red" }}>*</span></Label>
	      {interestsCheckBoxV && typeof interestsCheckBoxV === 'string' && <p className="error clearfix">{interestsCheckBoxV}</p>}
        
        <Row className="hlt-checkbox">
		  { /*  <DefaultInput
              name="cr676_events_tickets_interests"
              value={cr676_events_tickets_interests}
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              type="text"
              
		  />*/}
			{categoryList && categoryList.map((vals, index) => (
            <CustomInput      
              className="col-md-2 bg-transparent"			
              id= { index }
			 onChange={(event) => onChangeCheckEvent(event)}
              type="checkbox" name={vals.name}  checked = {formData[vals.name] === true } label={vals.label} />
          ))}
        
        </Row>
      </FormGroup>



      <FormGroup>
          <Label for="exampleCheckbox">Do you receive free event tickets from other organizations? <span style={{color: "red"}}> * </span></Label>
        <div className="d-flex">
                               {/* descriptionV */}
          <CustomInput validation={new_ticketsfromotherorganizationsV}  type="radio" id="exampleCustomRadio" name="new_ticketsfromotherorganizations"  //cr676_eventfreeticketothersdescription
                       value="true" onChange={(event) => onChangeEvent(event.target.name, event.target.value)} checked={new_ticketsfromotherorganizations === "true"} label="Yes" className="mr-3" />
          <CustomInput validation={new_ticketsfromotherorganizationsV} type="radio" id="exampleCustomRadio2" name="new_ticketsfromotherorganizations"
           value="false" onChange={(event) => onChangeEvent(event.target.name, event.target.value)} checked={new_ticketsfromotherorganizations === "false"}  label="No" />
        </div>
      </FormGroup>


      <FormGroup>  
         <Label>How many children do you serve in these categories?</Label>
        <Row>
          <Col md="3">
            <DefaultInput
              name="cr676_children_serve_0_6"
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              value={cr676_children_serve_0_6}
              validation = {children_serve_6V}
              type="text"
              placeholder="0 to 6 years"
            />
          </Col>
          <Col md="3">
            <DefaultInput
            name="cr676_children_serve_7_12"
            onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
            value={cr676_children_serve_7_12}
            validation = {children_serve_7V}
            type="text"
              placeholder="7 to 12 years"
            />
          </Col>
          <Col md="3">
            <DefaultInput
              name="cr676_children_serve_13_18"
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              validation = {children_serve_13V}
              value={cr676_children_serve_13_18}
              type="text"
              placeholder="13 to 18 years"
            />
          </Col>
          <Col md="3">
            <DefaultInput
            name="cr676_children_serve_0_6"
            value={bsi_numberofchildren}
              type="text"
              placeholder="Total"
            />
          </Col>
        </Row>
      </FormGroup> 

      <FormGroup>
          <Label for="exampleCheckbox">Kids Up Front often receives tickets with short turn-around time frames. Can your organization handle last minute tickets? <span style={{color: "red"}}> *</span></Label>
        <div className="d-flex">
          <CustomInput type="radio" validation = {short_ticketV} name="cr676_short_tickets_tat" id="mticket1" value={true} checked={cr676_short_tickets_tat === "true"} onChange={(event) => onChangeEvent(event.target.name, event.target.value)} label="Yes" className="mr-3" />
          <CustomInput type="radio" validation = {short_ticketV} id="mticket2" name="cr676_short_tickets_tat" value={false} checked={cr676_short_tickets_tat === "false"} onChange={(event) => onChangeEvent(event.target.name, event.target.value)} label="No" />
        </div>
      </FormGroup>
     {/* <FormGroup>
         {cr676_short_tickets_tat === "true" && <DefaultInput
              name="cr676_short_tickets_tat_description"
              value={cr676_short_tickets_tat_description}
              type="text"
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              placeholder=""
          />}
      </FormGroup>
	 */}

      <FormGroup>
          <Label for="exampleCheckbox">Is your organization able to pick up event tickets at the local Kids Up Front office?<span style={{color: "red"}}> * </span></Label>
        <div className="d-flex">
          <CustomInput type="radio" validation = {pickup_ticketV} id="pickup1" name="cr676_pickup_tickets_from_hq"  checked={cr676_pickup_tickets_from_hq === "true"} value={true} onChange={(event) => onChangeEvent(event.target.name, event.target.value)} label="Yes" className="mr-3" />
          <CustomInput type="radio" validation = {pickup_ticketV}  id="pickup2" name="cr676_pickup_tickets_from_hq"  checked={cr676_pickup_tickets_from_hq === "false"} value={false} onChange={(event) => onChangeEvent(event.target.name, event.target.value)} label="No" />
        </div>
      </FormGroup>
     {/* <FormGroup>
          {cr676_pickup_tickets_from_hq === "true" && <DefaultInput
              name="cr676_pickup_tickets_from_hq_description"
              value={cr676_pickup_tickets_from_hq_description}
              type="text"
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              placeholder=""

            />
          }
        </FormGroup>
        */}
        <FormGroup>
          <Label for="exampleCheckbox">
            What is your level of Commercial Liability Insurance coverage?<span style={{ color: 'red' }}> * </span>
          </Label>
          <div className="d-flex">
            <CustomInput
              type="radio"
              id="price1"
              validation = {liabilityV}
              name="new_liabilityinsurancelevel"
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              checked={new_liabilityinsurancelevel === 279640000}
              value="279640000"
              label="$1M"
              className="mr-3"
            />
            <CustomInput
              type="radio"
              id="price2"
              validation = {liabilityV}
              name="new_liabilityinsurancelevel"
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              checked={new_liabilityinsurancelevel === 279640001}
              value="279640001"
              label="$2M"
              className="mr-3"
            />
            <CustomInput
              type="radio"
              id="price3"
              validation = {liabilityV}
              name="new_liabilityinsurancelevel"
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              checked={new_liabilityinsurancelevel === 279640002}
              value="279640002"
              label="Over"
            />
          </div>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col sm="12" className="d-flex justify-content-end mt-3">
              <DefaultButton
                name="BACK"
                color="secondary"
                onClick={() => step2(this.state, true, false)}
                size="sm"
              />
              <DefaultButton
                name="CONTINUE"
                color="primary"
                disabled={checkValidationStep2}
                onClick={() => step2(this.state, false, true)}
                size="sm"
              />
            </Col>
          </Row>
        </FormGroup>

      </React.Fragment>

    )

  }

}

export default SignUpSteptwo;
