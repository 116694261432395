import { MEDIA_URL } from "./../utils/constants";

const randomImages = {
    861560000: [              //hockey
        `${MEDIA_URL}media/Agency%20Portal/Hockey/Hockey.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Hockey/Hockey2.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Hockey/Hockey3.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Hockey/Hockey4.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Hockey/Hockey5.jpg`,
    ],
    807710000: [                 //theatre
        `${MEDIA_URL}media/Agency%20Portal/Theatre/Kid Theatre.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Theatre/Theatre.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Theatre/young-people-eating-popcorn-in-movie-theater-PCKLMKX.jpg`,
    ],
    807710002: [                //concert
        `${MEDIA_URL}media/Agency%20Portal/Concerts/Concert.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Concerts/Concert2.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Concerts/bassist-pop-rock-during-a-performance-at-a-concert-P3EVYQ6.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Concerts/concert-crowd-PNSTMPE.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Concerts/music-concert-PPJF3CB.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Concerts/people-dancing-at-concert-75SQ9XG.jpg`,
    ],
    861560001: [               //football
        `${MEDIA_URL}media/Agency%20Portal/Football/american-football-ball-A27HUTF.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Football/american-football-game-PA7M4R5.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Football/american-football-player-PXMWTX7.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Football/american-football-player-QNLFEBG.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Football/Stadium.jpg`,
    ],
    861560003: [              //baseball
        `${MEDIA_URL}media/Agency%20Portal/Baseball/baseball-and-glove-PWMPTAY.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Baseball/baseball-ball-SCTWPPH.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Baseball/baseball-bat-and-ball-PMGK8ZA.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Baseball/Kid-baseball.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Baseball/old-baseball-and-base-in-dirt-infield-PTFE25C.jpg`,
    ],
    861560004: [          //soccer
        `${MEDIA_URL}media/Agency%20Portal/Soccer/close-up-of-soccer-ball-on-playing-field-GXF296H.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Soccer/close-up-of-soccer-player-with-ball-on-field-PEZ7S4R.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Soccer/foot-on-soccer-ball-PCP3AJD.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Soccer/low-section-of-soccer-player-and-ball-on-field-26QD9UV.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Soccer/soccer-goal-P7JCDUM.jpg`,
    ],
    861560005: [           //Dance
        `${MEDIA_URL}media/Agency%20Portal/Dance/ballerina-wearing-ballet-shoes-XHUJF7T.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Dance/ballet-student-exercising-in-ballet-costume-BKDV9GH.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Dance/legs-of-ballerina-woman-in-white-tutu-and-pointe-s-XGSFDXM.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Dance/the-group-of-modern-ballet-dancers-contemporary-ar-9LRVWCH.jpg`,
    ],
    random: [
        `${MEDIA_URL}media/Agency%20Portal/Dance/the-group-of-modern-ballet-dancers-contemporary-ar-9LRVWCH.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Soccer/soccer-goal-P7JCDUM.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Baseball/Kid-baseball.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Football/Stadium.jpg`,
        `${MEDIA_URL}media/Agency%20Portal/Concerts/people-dancing-at-concert-75SQ9XG.jpg`,
    ]

}

export default randomImages