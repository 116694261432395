import React from 'react'

function TermsAndConditions(){

    return (
        <div id='term-condition'>
              <p><strong>WHEREAS</strong> Kids Up Front provides access to fun, enriching experiences (the "<strong>Experiences</strong>") through our own means and/or as tickets to entertainment, recreational, and recreational events from various donors (the “<strong>Event Tickets</strong>) and works with various agencies, including but not limited to: registered charities, non - profit societies, government agencies, and schools to provide opportunities to high needs children, youth and families facing financial, and/or social barriers in our community;</p>

              <p><strong>AND WHEREAS</strong> the Agency agrees to enter into this Agency Agreement (the "<strong>Agreement</strong>") to be eligible to receive the Experiences from Kids Up Front;</p>

              <p><strong>AND WHEREAS</strong> this Agreement shall govern the terms and conditions by which the Experiences: (i) are distributed by Kids Up Front, in its sole discretion, to the Agency and; (ii) are subsequently distributed by the Agency to youth and children, of 18 years age and younger, who are connected with the Agency and are experiencing financial and/or social barriers, along with their respective parents, guardians and supervisors, as the case may be (the "<strong>Recipients</strong>");</p>

              <p><strong>NOW THEREFORE</strong>, in consideration of Kids Up Front providing the Experiences to the Agency and such further and other valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the Parties hereby acknowledge and agree as follows:</p>

              <p><strong>1. RELATIONSHIP OF THE PARTIES</strong></p>

              <p>1.1 The Parties agree and acknowledge that they must execute this Agreement in order for the Agency to become eligible to receive Experiences.</p>

              <p>1.2 The Agency acknowledges and agrees that it is not an employee, agent, or partner of Kids Up Front and at no time will the Agency represent or hold itself out to have any relationship with Kids Up Front other than as a Party to the Agreement.</p>

              <p><strong>2. REPRESENTATIONS AND WARRANTIES OF THE AGENCY</strong></p>

              <p>2.1 The Agency represents and warrants that it is a corporation, organization or other entity, in good standing under the laws under which it is constituted, and has all necessary power, authority and capacity to enter into and carry out its obligations under the Agreement.</p>

              <p><strong>3. OBLIGATIONS OF THE AGENCY</strong></p>

              <p>3.1 The Agency shall appoint one or more key contact persons for Kids Up Front (each, an "<strong>Agency Experience Coordinator</strong>"). The Agency covenants to: (i) provide Kids Up Front with the contact information for each Agency Experience Coordinator; and (ii) provide prompt written notice to Kids Up Front of any and all changes to Agency Experience Coordinators, and/or Agency Experience Coordinators' contact information to facilitate communication between Kids Up Front and the respective Agencies and to allow Kids Up Front to promptly contact Agency Experience Coordinators to disseminate Experiences.</p>
              <p>3.2 The Agency Experience Coordinator(s) are responsible for carrying out the Agency's responsibilities under this Agreement, which include but are not limited to: (i) attending training as prescribed by Kids Up Front from time to time; and (ii) offering Experiences to potential Recipients in a clear and timely fashion.</p>
              <p>3.3 The Agency agrees that it will not sell or exchange the Experiences such as event tickets or other materials under any circumstances for any form of compensation, including but not limited to, any monetary compensation, goods, or services, and that it will not distribute or provide the Experiences to individuals who are not Recipients. Without limiting the generality of the foregoing, the Agency agrees that it shall not, under any circumstances, be allowed to use Experiences: (i) directly or indirectly for Agency fundraising purposes; (ii) to compensate the staff of the Agency; and (iii) for any other internal purpose.</p>
              <p>3.4 The Agency agrees that it shall only distribute the Experiences such as event tickets and other materials to Recipients and shall not distribute the Experiences to staff or volunteers unless those staff or volunteers are acting as chaperone to the Recipients, and that it shall take all steps reasonably necessary to ensure that the Recipients that received the Experiences, use the Experiences. If Recipients are unable to use any event tickets that were provided, Agency Experience Coordinator(s) are required to notify Kids Up Front and to include a reasonable explanation.</p>
              <p>3.5 Event Tickets and other materials for Experiences may be distributed electronically, available for pick-up at the Kids Up Front office, or at will call at the venue. The Agency agrees that it is responsible for picking up all Experiences, as applicable or required, at either the Kids Up Front office, or will-call at the event venue.</p>
              <p>3.6 The Agency agrees that it shall make all reasonable efforts to ensure that Event Tickets are allocated and distributed to Recipients who are available to use the tickets, and if it is unable to identify any such Recipients or has Event Tickets returned, that the Event Tickets must be returned to Kids Up Front (even if under 24 hours' notice). The Agency acknowledges that Kids Up Front is not open on the weekends and that efforts to return the event tickets shall be made in accordance with such acknowledgement.</p>
              <p>3.7 The Agency shall bear the risk of and be responsible for any lost, stolen and missing Event Tickets. If Event Tickets are lost, stolen or otherwise missing, the Agency shall notify Kids Up Front immediately. Kids Up Front is not responsible for replacing lost, stolen, or missing Event Tickets.</p>
              <p>3.8 Drugs and/or alcohol are not to be consumed by Recipients before, during, or directly after attending any event accessed with Event Tickets.</p>
              <p>3.9 The Agency shall provide Kids Up Front with a written report specifying the use of the Event Tickets (the "<strong>Report</strong>") when asked by Kids Up Front to provide such Report. Kids Up Front reserves the right to request a Report at any time.</p>

              <p><strong>4. INSURANCE, LIABILITY AND INDEMNITY</strong></p>

              <p>4.1 The Agency shall obtain and maintain general liability and all risks insurance in a form and substance that covers the use of this Agreement and in an amount not less than $2,000,000 in Canadian dollars (the "<strong>Insurance</strong>"). Kids Up Front is under no obligation to provide any general liability and all risks insurance to the Agency.</p>

              <p>4.2 The Agency shall provide the certificates of insurance (the "<strong>Certificate</strong>") at the execution of this Agreement, at each renewal date of such insurance and from time to time upon the reasonable request of Kids Up Front. In the event the Agency does not have Insurance at execution, the Agency shall provide the Certificate no later than 15 days following execution of this Agreement.</p>

              <p><strong>5. LIABILITY AND INDEMNITY</strong></p>

              <p>5.1 The Agency covenants and agrees to indemnify and hold harmless to the maximum extent permitted by law, Kids Up Front, its officers, directors, employees, contractors, insureds, and assigns (each an "<strong>Indemnified Person</strong>" and collectively, the "<strong>Indemnified Persons</strong>"), from and against any and all losses, claims, suits, proceedings, investigations, costs, demands, damages, debts, dues, penalties, bonds, covenants, contracts, expenses, actions, and causes of action, liabilities or expenses of whatsoever nature or kind, whether in law or in equity, whether direct, indirect or consequential, and by reason of any cause whatsoever, including but not limited to negligence, breach of contract, or breach of any statutory, regulatory, or other duty of care in respect of any death, injury, loss, or damage to person or property and any and all liability, leases, damages, fines, costs, legal feels or penalties of whatever kind and however caused in connection with any action, suit, proceeding, investigation or claim that may be made or threatened against any Indemnified Person or in enforcing this indemnity (collectively, "<strong>Claims</strong>"), to which an Indemnified Person may become subject or otherwise involved in any capacity insofar as the Claims relate to, are caused by, result from, arise out of or are based upon, directly or indirectly, this Agreement, including but not limited to the distribution of the Event Tickets by Kids Up Front, the use and distribution of the Event Tickets by the Agency, and the use of the Event Tickets and the attendance at any such events by the Recipient(s), and to reimburse each Indemnified Person, upon demand, for any legal or other expenses incurred by such Indemnified Person in connection with any Claim.</p>

              <p>5.2 The Agency acknowledges and accepts that in the event of any Claims relating to, arising from, or in connection with this Agreement, Kids Up Front shall not accept any responsibility. The Agency covenants and agrees not to make any Claims or pursue any proceedings against an Indemnified Person, whether under the provisions of any statute or otherwise, with respect to any Claims arising from or relating in any way to the this Agreement, including but not limited to the distribution of the Event Tickets by Kids Up Front, the use and distribution of the Event Tickets by the Agency, and the use of the Event Tickets and the attendance at any such events by the Recipient(s), notwithstanding that either or both of the Claims may arise from Kids Up Front’s negligence, willful act or omission or other conduct, breach of contract, or breach of any statutory, regulatory, or other duty of care.</p>
              <p>5.3 For greater certainty, and without limiting the foregoing, the Agency is responsible for ensuring the standards of behavior for the Recipient(s) using Event Ticket(s), is in accordance with the Kids Up Front Policies.</p>
              <p>5.4 Each of the Parties acknowledge it has had adequate time to review this Agreement, particularly as it relates to this section 5, prior to execution and have obtained their own independent legal advice with respect to the terms and conditions of this Agreement prior to its execution. Each Party acknowledges that it is freely and voluntarily entering into this Agreement, without duress or undue influence. Each Party acknowledges that the contra proferentem rule shall not apply in interpretation of the provision hereunder or any issues of ambiguity with respect thereto.</p>

              <p><strong>6. TERMINATION</strong></p>

              <p>6.1 Either of the Parties may terminate this Agreement upon seven business days written notice to the other Party. Once the seven business days lapse, the relationship between the Parties shall be deemed terminated and the Agency will immediately return any undistributed Event Tickets or other materials for Experiences to Kids Up Front.</p>

              <p>6.2 The Agency acknowledges and agrees that if it breaches this term or any other term of this Agreement that any distribution of Event Tickets may be suspended at the sole discretion of Kids Up Front, or Kids Up Front may terminate this Agreement effective immediately.</p>

              <p><strong>7. PRIVACY AND PROMOTIONAL MATERIAL</strong></p>

              <p>7.1 The Agency acknowledges and agrees that it is responsible for compliance at all times with applicable privacy laws which govern the collection, use and disclosure of any and all personal information collected, used or disclosed by the Agency in connection with the Agreement and its relationship with Kids Up Front, including but not limited to the Personal Information Protection and Electronic Documents Act ("<strong>PIPEDA</strong>") (the "<strong>Privacy Laws</strong>") and Provincial Personal Information Protection Acts (“<strong>PIPA</strong>”).</p>
              <p>7.2 Kids Up Front agrees that all information it collects shall be collected, used and disclosed in accordance with the Kids Up Front Privacy Policy (the "<strong>Kids Up Front Privacy Policy</strong>"), found on our website.</p>
              <p>7.3 The Agency, alone or in cooperation with Kids Up Front, agrees to make every effort to gain written consent from the Recipients, in a form acceptable to Kids Up Front, regarding the Recipients' participation in any Kids Up Front promotional events including but not limited to photographs, video, and social media.</p>
              <p>7.4 The Parties agree that they will obtain the other Party’s prior written approval and consent prior to the use of the other Party’s logos or promotional materials.</p>
              <p>7.5 The Agency acknowledges that Kids Up Front may use impact stories from Recipients from the Agency, arising from the use of an Event Ticket by a Recipient, and these impact stories may be posted on the Kids Up Front website or other forms of social media and may be used in Kids Up Front advertising or funding proposals from time to time. Kids Up Front shall obtain consent, as applicable, from a Recipient or guardian prior to using a Recipient’s impact story, in accordance with applicable Privacy Laws and Kids Up Front's Privacy Policy.</p>

              <p><strong>8. ASSIGNMENT</strong></p>

              <p>8.1 There shall be no transfer or assignment of this Agreement without the prior written consent of Kids Up Front, such consent may be withheld at Kids Up Front's sole discretion.</p>
              <p><strong>9. SEVERABILITY</strong></p>

              <p>9.1 If any provision of the Agreement or its application to any Party or circumstance is determined by a court of competent jurisdiction to be illegal, invalid, or unenforceable, it will be ineffective only to the extent of its illegality, invalidity or unenforceability.</p>

              <p><strong>10. ENTIRE AGREEMENT</strong></p>

              <p>10.1 This Agreement constitutes the entire agreement between the Parties with respect to the subject matter of this Agreement and supersedes all other understandings, agreements, representations (including misrepresentations, negligent, or otherwise), negotiations, communications, and discussions, written, or oral, made by the Parties with respect thereto. There are no representations, warranties, terms, conditions, covenants or other understandings, express or implied, collateral, statutory or otherwise, between the Parties, except as expressly stated in this Agreement. The Parties have not relied and are not relying on any other information, discussion or understanding in entering into and completing the transactions contemplated by this Agreement.</p>

              <p><strong>11. GOVERNING LAW</strong></p>

              <p>11.1 This Agreement will be construed, interpreted, and enforced in accordance with provincial laws and the federal laws of Canada applicable therein. Each Party irrevocably attorns and submits to the exclusive jurisdiction of the Provincial Court in your province and irrevocably waives objection to the venue of any proceeding in those courts or that those courts provide an inconvenient forum.</p>

              <p><strong>12. DELIVERY</strong></p>

              <p>12.1 This Agreement may be executed in any number of counterparts (including counterparts by facsimile), each of which will be deemed to be an original and all of which taken together will be deemed to constitute one and the same instrument. Delivery by facsimile or by electronic transmission of an executed counterpart of this Agreement is as effective as delivery of an originally executed counterpart of this Agreement. Any Party delivering an executed counterpart of this Agreement by electronic transmission shall also deliver an originally executed counterpart of this Agreement, but the failure to deliver an originally executed copy does not affect the validity, enforceability or binding effect of this Agreement.</p>

            </div>
          
    )
}

export default TermsAndConditions