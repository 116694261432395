/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Logout from "../../../UI/molecules/Logout";
import {
  FormGroup,
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import SideNav, { Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { Icon } from './../../../UI/atoms/Icon';
import classnames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import DefaultButton from './../../../UI/atoms/DefaultButton';
import { DefaultInput } from '../../../UI/atoms/index';
import { GenerateTicketRequestModal } from '../EventsManagement/GenerateTicketRequestModal';
import GenerateTicketRequestModalLimited from "../EventsManagement/GenerateTicketRequestModalLimited";
import { ActionButtons, TicketAllocationActionButtons } from './../../../UI/atoms/ActionButtons';
import { ActionButtonsAgency } from '../../../UI/atoms/ActionButtonsAgency';
import AgencyPopover from './agencyPopover';
import ClientPopover from './clientPopover';
import { checkValid, validate, validateSharedModel, validateChangeReq } from '../../../../utils/validations';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { numberFormat } from '../../../UI/molecules/NumberFormat'
import {
  getEventDetails,
  getEventClientRequests,
  onSaveTicketAllocated,
  getEventAgencyRequest,
  changeRequestAgencyRequest,
  getTicketAllocationRequest,
  generateAgencyTicketRequest,
  generateTicketRequest,
  updateBatchAgencyRequest,
  insertKey,
  allocateTicket,
  modifyTicketAllocationRequest,
  totalReceivedAndAllocatedTickets,
  updateBatchSharedRequest,
  getAgencyTotalTickets,
  sendEmailOnChangeStatus,
  saveChangeAgencyRequest,
  getChangeAgencyRequest,
  deleteRequestAgencyRequest,
  getRequestTotalTickets,
  getClientHoverData,
  getEventCategories,
  sendEmailOnRejectClientTickets
} from '../../../../actions/EventActions';

import { get, find } from 'lodash'
import moment from 'moment';
import { toast } from 'react-toastify';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import $ from "jquery";
const selectOptions = {
  'Accepted': 'Accepted',
  'Rejected': 'Rejected',
  'Processed': 'Processed',
  'Pending': 'Pending'
};

const selectAgencyOptions = {
  'Accepted': 'Accepted',
  'Pending': 'Pending',
  'Change Requested': 'Change Requested',
  'Change Allocated': 'Change Allocated'
};


const selectAllocationOptions = {
  'Allocated': 'Allocated',
  'Shared': 'Shared',
  'Pending': 'Pending'
};


const displayStatus = ({ ticketStatus, activeTab }) => {
  if (ticketStatus === 807710000 || ticketStatus === 476940006) {
    return "Pending"
  }
  else if (ticketStatus === 279640000 ) {
    return "Approved"
  }    
  else if (ticketStatus === 807710002) {
    return "Accepted"
  } else if (ticketStatus === 807710001) {
    return "Cancelled"
  } else if (ticketStatus === 807710003) {
    return "Rejected"
  } else if (ticketStatus === 476940000 && activeTab === '3') {
    return "Pending"
  } else if (ticketStatus === 476940000) {
    return "Processed"
  } else if (ticketStatus === 476940001) {
    return "Change Requested"
  } else if (ticketStatus === 476940002) {
    return "Change Allocated"
  } else if (ticketStatus === 476940003 && activeTab === '1') {
    return "Processed"
  } else if (ticketStatus === 476940003) {
    return "Allocated"
  } else if (ticketStatus === 476940004 && activeTab === '1') {
    return "Processed"
  } else if (ticketStatus === 476940004) {
    return "Shared"
  } else if (ticketStatus === 476940005) {
    return "Denied"
  } else {
    return "Invalid"
  }
}

const displayAgencyStatus = ({ ticketStatus }) => {
  if (ticketStatus === 807710000)
    return "Pending"
  else if (ticketStatus === 279640000)
    return 'Approved'
  else if (ticketStatus === 476940006)
    return 'Partially Approved'
  else if (ticketStatus === 807710002)
    return "Allocated"
  else if (ticketStatus === 807710001)
    return "Cancelled"
  else if (ticketStatus === 807710003)
    return "Denied"
  else if (ticketStatus === 476940000)
    return "Processed"
  else if (ticketStatus === 476940001)
    return "Change Requested"
  else if (ticketStatus === 476940002)
    return "Change Approved"
  else if (ticketStatus === 476940004)
    return "Shared"
  else if (ticketStatus === 476940005)
    return "Change Request Denied"
  else if (ticketStatus === 476940003)
    return "Allocated By Agency"
  else
    return "Invalid"
}

const EventPrimaryData = ({ eventDetails, totalReceivedAndAllocatedTickets, totalAgencyTicket, totalRequestTicket, userPermission, image, getEventCategories }) => {
  const totalTickets = totalReceivedAndAllocatedTickets && totalReceivedAndAllocatedTickets.value ? totalReceivedAndAllocatedTickets.value[0] : null;
  const totalRequest = totalRequestTicket && totalRequestTicket.value ? totalRequestTicket.value[0] : null;
  const totalRecieved = totalAgencyTicket && totalAgencyTicket.value ? totalAgencyTicket.value[0].agency_total_tickets : null;
  const address = [eventDetails.evtech_addressline1, eventDetails.evtech_addressline2, eventDetails.evtech_addressline3].join((' '));
  const eventImage = eventDetails && eventDetails.cr676_image ? `data:image/jpeg;base64,${eventDetails.cr676_image}` : image;
  return (
    <>
      <Row>
        <Col xl="4">
          <img style={{ maxWidth: "100%", width: "100%" }} src={eventImage} alt={eventDetails.evtech_name} />
        </Col>
        <Col xl="8">
          <div className="event-summary">
            <Row>
              <Col xl="6">
                <div className="event-summary-box">
                  <Icon name="calendar" />
                  <h6>Start Date &amp; Time:</h6>
                  <span className="data">{(eventDetails && eventDetails.evtech_startdate) ? moment.utc(eventDetails.evtech_startdate).format('lll') : 'N/A'}</span>
                </div>
                <div className="event-summary-box">
                  <Icon name="calendar" />
                  <h6>End Date &amp; Time:</h6>
                  <span className="data">{(eventDetails && eventDetails.evtech_enddate) ? moment.utc(eventDetails.evtech_enddate).format('lll') : 'N/A'}</span>
                </div>
                <div className="event-summary-box">
                  <Icon name="list" />
                  <h6>Category</h6>

                  <span class="data">

                    {getEventCategories.findIndex(data => Number.parseInt(data.value, 10) === eventDetails.evtech_eventcategory) !== -1 ? getEventCategories[getEventCategories.findIndex(data => Number.parseInt(data.value, 10) === eventDetails.evtech_eventcategory)].label : ""}



                  </span>
                </div>
                <div className="event-summary-box">
                  <Icon name="users" />
                  <h6>Age Range</h6>
                  <span class="data">{eventDetails && eventDetails.evtech_agerange}</span>
                </div>
                <div className="event-summary-box">
                  <Icon name="address-book" />
                  <h6>Contact Details:</h6>
                  <span class="data">{eventDetails && eventDetails.new_contactphone}</span>
                </div>
              </Col>
              <Col xl="6">
                <div className="tickets-summary">
                  {userPermission && <p>CLIENT REQUESTS :{totalTickets && totalTickets.total_requested ? totalTickets.total_requested : 0}</p>}
                  {userPermission && <p> REQUESTED EXPERIENCES :{totalRequest && totalRequest.total_requested_by_agency ? totalRequest.total_requested_by_agency : 0}</p>}
                  <p>RECEIVED EXPERIENCES :{totalRecieved ? totalRecieved : 0} </p>
                  {userPermission && <p>AVAILABLE EXPERIENCES : {totalRecieved && totalTickets ? totalRecieved - totalTickets.total_allocated : 0} </p>}
                  {userPermission && <p>ALLOCATED EXPERIENCES : {totalTickets && totalTickets.total_allocated ? totalTickets.total_allocated : 0} </p>}
                </div>
                <div className="event-summary-box">
                  <Icon name="map-marker" />
                  <h6>Location:</h6>
                  {/* <p><Icon  /> {event.evtech_venueid ? event.evtech_venueid.evtech_name : ''}</p> */}

                  <span class="data">{eventDetails.evtech_venueid ? eventDetails.evtech_venueid.evtech_name : ''}</span>
                </div>
                <div className="event-summary-box">
                  <Icon name="address-book" />
                  <h6>Address:</h6>
                  <span class="data">{eventDetails && address ? address : "-"}</span>
                </div>
              </Col>
              <Col xl="12">
                <div className="event-summary-box details">
                  <h6>Details:</h6>
                  <span class="data">{eventDetails && eventDetails.evtech_description}</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}
class eventDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      activeTab: "1",
      modal: {
        isVisible: false,
        data: {}
      },
      getEventCategories: [],
      subheader: true,
      agencySubheader: true,
      ticketSubheader: true,
      setNameFilter: '',
      setEmailFilter: '',
      setClientStatusFilter: '',
      columnHeaderElement: {
        name: true,
        filter: false,
        setNameFilter: ''
      },
      columnEmailElement: {
        name: true,
        filter: false
      },
      columnStatusElement: {
        name: true,
        filter: false
      },
      setAgencyNameFilter: '',
      setAgencyStatusFilter: '',
      agencyNameElement: {
        name: true,
        filter: false
      },
      agencyStatusElement: {
        name: true,
        filter: false
      },
      setTicketNameFilter: '',
      setTicketEmailFilter: '',
      setTicketStatusFilter: '',
      ticketNameElement: {
        name: true,
        filter: false
      },
      ticketEmailElement: {
        name: true,
        filter: false
      },
      ticketStatusElement: {
        name: true,
        filter: false
      },
      modifyingRows: {},
      modifyAllocationRow: {},
      modifyingAgencyRows: {},
      requestConformTickets: '',
      requestevtech_ticketrequestid: '',
      requestComments: '',
      requestChildren: '',
      requestChaperones: '',

      requestActualTotalTickets: '',
      requestActualChaperones: '',
      requestActualChildren: '',
      changeReqDateArray: '',
      subject: '',
      emailbody: '',
      validationSharedModel: {
        subjectV: false,
        emailbodyV: false
      },
      validationChangeReq: {
        adultV: false,
        childrenV: false
      },
      isChange: false,
      images: [
        { name: "Event 1", url: "https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 2", url: "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 3", url: "https://images.pexels.com/photos/1047442/pexels-photo-1047442.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 4", url: "https://images.pexels.com/photos/1684187/pexels-photo-1684187.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 5", url: "https://images.pexels.com/photos/1449791/pexels-photo-1449791.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 6", url: "https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 7", url: "https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 8", url: "https://images.pexels.com/photos/2774556/pexels-photo-2774556.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 9", url: "https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 10", url: "https://images.pexels.com/photos/2399097/pexels-photo-2399097.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 11", url: "https://images.pexels.com/photos/3023317/pexels-photo-3023317.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 12", url: "https://images.pexels.com/photos/110472/pexels-photo-110472.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" }
      ],
      agencyTicketRequestModel: {
        isVisible: false,
        data: {}
      }
    }
  }

  componentDidMount = () => {
    this.startFetchingEventClientRequests();
    const userPermission = this.props.user ? this.props.user.data && this.props.user.data.userPermission : null;
    const currentActiveTab = userPermission ? "1" : "2";
    this.props.getEventCategoriesFn();
    this.setState({
      activeTab: currentActiveTab
    })
    if (this.props && this.props.location && this.props.location.state && this.props.location.state.tab) {
      this.setState({
        activeTab: this.props.location.state.tab
      })
    }

    this.startFetchingAgencyRequests()
    // localStorage.removeItem("headerId")

  }


  startFetchingEventClientRequests = () => {
    const eventId = this.props.match.params && this.props.match.params.id;
    // const userId = this.props.dynamic && this.props.dynamic._owninguser_value;
    this.props.getEventDetails(eventId);
    if (this.props.agencyGuid) {
      this.props.getEventAgencyRequest(this.props.agencyGuid, eventId);
      this.props.getAgencyTotalTickets(this.props.agencyGuid, eventId);
    }

    if (this.props.agencyCode) {
      this.props.getTicketAllocationRequest(this.props.agencyCode, eventId);
      this.props.getEventClientRequests(this.props.agencyCode, eventId);
      this.props.getRequestTotalTickets(this.props.agencyCode, eventId);
    }

  }

  startFetchingAgencyRequests = () => {
    const eventId = this.props.match.params && this.props.match.params.id;
    if (this.props.agencyGuid && this.props.agencyCode) {
      this.props.getEventAgencyRequest(this.props.agencyGuid, eventId);
      this.props.getTotalReceivedAndAllocatedTickets(this.props.agencyCode, eventId);
      this.props.getRequestTotalTickets(this.props.agencyCode, eventId);
    }
  }

  async componentWillReceiveProps(props) {
    const eventId = this.props.match.params && this.props.match.params.id;
    if (this.state.isChange && props.agencyChangeRequests && props.agencyChangeRequests.status === 204) {
      await this.props.getEventAgencyRequest(this.props.agencyGuid, eventId);
      if (this.state.modalRequest) {
        toast.success('Change request added successfully');
      } else {
        toast.success('Change request canceled successfully');
      }
      this.setState({ isChange: false })
      this.setState({ modalRequest: false })

    }

    if (props.updateSharedTicketAllocation && this.state.modalSharedRequest) {
      this.setAllocationTab();
      this.setState({
        modalSharedRequest: false
      })
      this.setState({
        modifyAllocationRow: {}
      })
    }
  }

  generateTicketRequest = () => {
    const data = this.props.eventClientRequests ? this.props.eventClientRequests.filter((req) => {
      const ticketRequestId = req.evtech_ticketrequestid;
      const ticketState = this.state.modifyingRows[ticketRequestId];
      const status = (ticketState && ticketState.status) || req.evtech_requeststatus;
      return status === 807710002
    }).reduce((acc, curr) => {
      acc.emailContent.push({
        to_email: curr.emailaddress,
        client_name: curr.evtech_chaperonename,
        event_name: curr.cr676_event_name,
        agency: this.props.dynamic?.parentcustomerid_account?.name,
        business_unit: this.props.business_detail?.name
      });
      acc.ticketIds.push(curr.evtech_ticketrequestid);
      acc.evtech_numberofchaperones = parseInt(acc.evtech_numberofchaperones) + parseInt(curr.evtech_numberofchaperones);
      acc.evtech_numberofchildren = parseInt(acc.evtech_numberofchildren) + parseInt(curr.evtech_numberofchildren);
      return acc;
    }, {
      emailContent: [],
      evtech_numberofchaperones: 0,
      evtech_numberofchildren: 0,
      ticketIds: []
    }) : {}

    this.setState({ modal: { ...this.state.modal, isVisible: !this.state.modal.isVisible, data: data }, modifyingRows: {} })
  };

  agencyGenerateTicketRequest = () => {
    this.setState({ agencyTicketRequestModel: { ...this.state.agencyTicketRequestModel, isVisible: true } })
  }

  modalToggleRequest = () => this.setState({
    modalRequest: false,
    requestConformTickets: '',
    requestevtech_ticketrequestid: '',
    requestComments: '',
    requestChildren: '',
    requestChaperones: ''
  })

  modalSharedToggleRequest = () => this.setState({
    modalSharedRequest: false
  })

  sharedTicketRequest = () => this.setState({
    modalSharedRequest: true
  })

  editTicket = (ticketRequestId, event) => {
    const target = event.target;
    const name = target.name;
    const currentRow = this.state.modifyingRows[ticketRequestId];
    const modifyingRows = {
      ...this.state.modifyingRows,
      [ticketRequestId]: {
        ...currentRow,
        [name]: target.value
      }
    }
    this.setState({
      modifyingRows
    })
  }

  editTicketAllocation = (ticketRequestId, event) => {
    const target = event.target;
    const name = target.name;
    const currentRow = this.state.modifyAllocationRow[ticketRequestId];
    const modifyAllocationRow = {
      ...this.state.modifyAllocationRow,
      [ticketRequestId]: {
        ...currentRow,
        [name]: target.value,
        totalTicketAllocationToBeRequest:
          ((name === "adultTicketAllocationToBeRequest") ?
            parseInt(target.value) :
            parseInt(currentRow.adultTicketAllocationToBeRequest)) + ((name === "childrenTicketAllocationToBeRequest")
              ? parseInt(target.value) : parseInt(currentRow.childrenTicketAllocationToBeRequest)),
      }
    }
    this.setState({
      modifyAllocationRow
    })
  }

  editAgencyTicket = (ticketRequestId, event) => {
    const target = event.target;
    const name = target.name;
    const currentRow = this.state.modifyingAgencyRows[ticketRequestId];
    const modifyingAgencyRows = {
      ...this.state.modifyingAgencyRows,
      [ticketRequestId]: {
        ...currentRow,
        [name]: target.value,
        AgencyTotalTickets: ((name === "AgencyAdultToBeRequest") ? parseInt(target.value) : parseInt(currentRow.AgencyAdultToBeRequest)) + ((name === "AgencyChildrenToBeRequest") ? parseInt(target.value) : parseInt(currentRow.AgencyChildrenToBeRequest)),
      }
    }
    this.setState({
      modifyingAgencyRows
    })
  }

  totalAdultTicketAllow = (string1, string2, ticketRequestId, isModifying = false) => {
    return (
      <table className="double-conent">
        {/* <tbody> */}
        <tr>
          <td>{string1}</td>
          <td>{isModifying ?
            <input
              name="adultToBeRequest"
              type="number"
              style={{ width: "3em" }}
              min={"0"}
              defaultValue={string2}
              value={this.state.modifyingRows[ticketRequestId].adultToBeRequest}
              onChange={(event) => this.editTicket(ticketRequestId, event)}
            />
            : string2}
          </td>
        </tr>
        {/* </tbody> */}
      </table>
    );
  }

  agencyRequestsReceived = (string1, string2, string3, isModifying= false) => {
    return (
      <table className="double-conent">
        <tr>
          <td>{string1 || 0}</td>
          <td>{string2 || 0}</td>
          <td>{string3 || 0}</td>
        </tr>
      </table>
    );
  }

  //  agencyRequestsReceived = (string1, string2, string3, ticketRequestId, isModifying= false) => {
  //   return (
  //     <table className="double-conent">
  //       <tr>
  //         <td>
  //           {isModifying ?
  //           <>
  //               <input
  //               name="AgencyAdultToBeRequest"
  //               type="number"
  //               style={{ width: "3em" }}
  //               defaultValue={this.state.modifyingAgencyRows[ticketRequestId].requestsReceived ? this.state.modifyingAgencyRows[ticketRequestId].requestsReceived : string3}
  //               value={this.state.modifyingAgencyRows[ticketRequestId].requestsReceived}
  //               onChange={(event) => this.editAgencyTicket(ticketRequestId, event)}
  //             />

  //             </>
  //               :
  //               string1}
  //         </td>
  //         <td>{string2 || 0}</td>
  //         <td>{string3 || 0}</td>
  //       </tr>
  //     </table>
  //   );
  // }

  agencyRequestsToBeSent = (string1, string2, string3, isModifying = false, ticketRequestId) => {
    return (
      <table className="double-conent">
        <tr>
          <td>{isModifying ?
            <input
              name="adultToBeRequest"
              type="number"
              style={{ width: "3em" }}
              min={"0"}
              defaultValue={string1}
              value={this.state.modifyingRows[ticketRequestId].adultToBeRequest}
              onChange={(event) => this.editTicket(ticketRequestId, event)}
            />
            : string1}
          </td>
          <td>{isModifying ?
            <input
              name="childrenToBeRequest"
              type="number"
              style={{ width: "3em" }}
              min={"0"}
              defaultValue={string2}
              value={this.state.modifyingRows[ticketRequestId].childrenToBeRequest}
              onChange={(event) => this.editTicket(ticketRequestId, event)} />
            :
            string2}
          </td>
          <td>{string3}</td>
        </tr>
        {/* </tbody> */}
      </table>
    );
  }
  totalChildrenTicketAllow = (string1, string2,string3, isModifying = false, ticketRequestId) => {
    return (
      <table className="double-conent">
        {/* <tbody> */}
        <tr>
          {/* <td>{string1}</td> */}

          <td>{isModifying ?
            <input
              name="adultToBeRequest"
              type="number"
              style={{ width: "3em" }}
              min={"0"}
              defaultValue={string1}
              value={this.state.modifyingRows[ticketRequestId].adultToBeRequest}
              onChange={(event) => this.editTicket(ticketRequestId, event)} />
            :
            string1}
          </td>
          <td>{isModifying ?
            <input
              name="childrenToBeRequest"
              type="number"
              style={{ width: "3em" }}
              min={"0"}
              defaultValue={string2}
              value={this.state.modifyingRows[ticketRequestId].childrenToBeRequest}
              onChange={(event) => this.editTicket(ticketRequestId, event)} />
            :
            string2}
          </td>
          <td>{isModifying ?
            <input
              name="totalToBeRequest"
              disabled
              type="number"
              style={{ width: "3em" }}
              min={"0"}
              defaultValue={string3}
              value={this.state.modifyingRows[ticketRequestId].totalToBeRequest}
              onChange={(event) => this.editTicket(ticketRequestId, event)}
            />
            : string3}
          </td>
        </tr>
        {/* </tbody> */}
      </table>
    );
  }


  totalAgencyAllow = (string1, string2, string3, isModifying = false, ticketRequestId, onlyTotal = false) => {
    return (
      <table className="double-conent">
        {/* <tbody> */}
        <tr>{
          !onlyTotal && <>
            <td>{isModifying ?
              <input
                name="AgencyAdultToBeRequest"
                type="number"
                style={{ width: "3em" }}
                defaultValue={this.state.modifyingAgencyRows[ticketRequestId].AgencyAdultToBeRequest ? this.state.modifyingAgencyRows[ticketRequestId].AgencyAdultToBeRequest : string3}
                value={this.state.modifyingAgencyRows[ticketRequestId].AgencyAdultToBeRequest}
                onChange={(event) => this.editAgencyTicket(ticketRequestId, event)}
              />
              :
              string1}
            </td>
            <td>{isModifying ?
              <input
                name="AgencyChildrenToBeRequest"
                type="number"
                style={{ width: "3em" }}
                defaultValue={this.state.modifyingAgencyRows[ticketRequestId].AgencyChildrenToBeRequest ? this.state.modifyingAgencyRows[ticketRequestId].AgencyChildrenToBeRequest : string2}
                value={this.state.modifyingAgencyRows[ticketRequestId].AgencyChildrenToBeRequest}
                onChange={(event) => this.editAgencyTicket(ticketRequestId, event)}
              />
              :
              string2}
            </td>
          </>
        }
          <td>{isModifying ?
            <input
              name="AgencyTotalTickets"
              type="number"
              style={{ width: "3em" }}
              defaultValue={this.state.modifyingAgencyRows[ticketRequestId].AgencyTotalTickets ? this.state.modifyingAgencyRows[ticketRequestId].AgencyTotalTickets : string1}
              value={this.state.modifyingAgencyRows[ticketRequestId].AgencyTotalTickets}
              disabled
              onChange={(event) => this.editAgencyTicket(ticketRequestId, event)}
            />
            :
            string3}
          </td>         
        </tr>

        {/* </tbody> */}
      </table>
    );
  }



  doubleHeader = ({ string1, string2, string3 }) => {
    return (
      <table width="100%" className="innerHeading">
        <tr>
          <td>{string1}<br />{string2}</td>
          <td>{string1}<br />{string3}</td>
        </tr>
      </table>
    )
  }

  allocationHeader = ({ string1, string2, string3 }) => {
    return (
      <table width="100%" className="innerHeading">
        <tr>
          <td>{string1}</td>
          <td>{string2}</td>
          <td>{string3}</td>
        </tr>
      </table>
    )
  }

  tripleHeader = ({ string1, string2, string3 }) => {

    return (
      <table width="100%" className="innerHeading">
        <tr>
          <td>{string1}</td>
          <td>{string2}</td>
          <td>{string3}</td>
        </tr>
      </table>
    )
  }

  singleHeader = ({ string3 }) => {
    return (
      <table width="100%" className="innerHeading">
        <tr>
          {/* <td>{string1}</td>
          <td>{string2}</td> */}
          <td>{string3}</td>
        </tr>
      </table>
    )
  }

  totalTicketAllocation = (string1, string2, isModifying = false, ticketRequestId) => {
    return (
      <table className="double-conent">
        {/* <tbody> */}
        <td>{string1}</td>
        <td>{isModifying ?
          <input
            name="totalTicketAllocationToBeRequest"
            disabled
            type="number"
            style={{ width: "3em" }}
            min={"0"}
            defaultValue={string2}
            value={this.state.modifyAllocationRow[ticketRequestId].totalTicketAllocationToBeRequest}
            onChange={(event) => this.editTicket(ticketRequestId, event)}
          />
          : string2}
        </td>
        {/* </tbody> */}
      </table>
    );
  }

  totalAdultTicketAllocation = (string1, string2, string3) => {
    return (
      <table className="double-conent">
        {/* <tbody> */}
        <tr>
          <td>{string1}</td>
          <td>{string2}</td>
          <td>{string3}</td>
        </tr>
        {/* </tbody> */}
      </table>
    );
  }

  totalChildrenTicketAllocation = (string1, string2, string3, isModifying = false, ticketRequestId) => {
    return (
      <table className="double-conent">
        {/* <tbody> */}
        <tr>
          <td>{isModifying ?
            <input
              name="adultTicketAllocationToBeRequest"
              type="number"
              style={{ width: "3em" }}
              min={"0"}
              defaultValue={string1}
              value={this.state.modifyAllocationRow[ticketRequestId].adultTicketAllocationToBeRequest}
              onChange={(event) => this.editTicketAllocation(ticketRequestId, event)} />
            :
            string1}
          </td>
          <td>{isModifying ?
            <input
              name="childrenTicketAllocationToBeRequest"
              type="number"
              style={{ width: "3em" }}
              min={"0"}
              defaultValue={string2}
              value={this.state.modifyAllocationRow[ticketRequestId].childrenTicketAllocationToBeRequest}
              onChange={(event) => this.editTicketAllocation(ticketRequestId, event)} />
            :
            string2}
          </td>
          <td>{isModifying ?
            <input
              name="totalTicketAllocationToBeRequest"
              disabled
              type="number"
              style={{ width: "3em" }}
              min={"0"}
              defaultValue={string3}
              value={this.state.modifyAllocationRow[ticketRequestId].totalTicketAllocationToBeRequest}
              onChange={(event) => this.editTicket(ticketRequestId, event)}
            />
            : string3}
          </td>
        </tr>
        {/* </tbody> */}
      </table >
    );
  }

  modifyTicket = ( request) => {
    const clientTicket = this.state.modifyingRows[request.ticketRequestId]||{};
    const modifyingRows = {
      ...this.state.modifyingRows,
      [request.ticketRequestId]: {
        ...clientTicket,
        totalTicketsTbs: request.totalTicketsTbs,
        adultToBeRequest: request.totalAdultTicketsTbs,
        childrenToBeRequest:request.totalChildrenTicketsTbs,
        isModifying: true,
        // isModified: true
      }
    }
    this.setState({
      modifyingRows: modifyingRows
    })
  }


  onEditAgencyRequest = (request, totalAgencyReq, totalAdultAgencyReq, totalChildrenAgencyReq) => {
    const ticketAgency = this.state.modifyingAgencyRows[request] || {};
    const modifyingAgencyRows = {
      ...this.state.modifyingAgencyRows,
      [request]: {
        ...ticketAgency,
        AgencyTotalTickets: totalAgencyReq,
        AgencyAdultToBeRequest: totalAdultAgencyReq,
        AgencyChildrenToBeRequest: totalChildrenAgencyReq,
        isModifying: true
      }
    }
    this.setState({
      modifyingAgencyRows: modifyingAgencyRows
    })
  }

  onModifyAllocation = (request, totalAdultTicketsTbs, totalChildrenTicketsTbs, totalTicketsTbs) => {
    const ticketAllocation = this.state.modifyAllocationRow[request.ticketRequestId] || {};
    const modifyAllocationRow = {
      ...this.state.modifyAllocationRow,
      [request.ticketRequestId]: {
        ...ticketAllocation,
        adultTicketAllocationToBeRequest: request.totalAdultTicketsTbs,
        childrenTicketAllocationToBeRequest: request.totalChildrenTicketsTbs,
        totalTicketAllocationToBeRequest: request.totalTicketsTbs,
        isModifying: true
      }
    }
    this.setState({
      modifyAllocationRow: modifyAllocationRow
    })
  }

  onSaveChanges = ({ ticketRequestId }) => {
    const eventId = this.props.match.params && this.props.match.params.id;
    const agencyCode = this.props.agencyCode;
    const ticket = this.state.modifyingRows[ticketRequestId] || {};
    const totalTickets = parseInt(ticket.childrenToBeRequest) + parseInt(ticket.adultToBeRequest);
    const bodyParams = {
      "evtech_numberofchildren": ticket.childrenToBeRequest,
      "evtech_numberofchaperones": ticket.adultToBeRequest,
      "evtech_requeststatus": 807710000,
      "evtech_totaltickets": totalTickets,
      "cr676_children_allocate": ticket.childrenToBeRequest,
      "cr676_adult_allocate": ticket.adultToBeRequest,
      "cr676_total_allocated": totalTickets,

    }
    this.props.onSaveChanges(ticketRequestId, agencyCode, eventId, bodyParams);
    this.setState({
      modifyingRows: {
        ...this.state.modifyingRows,
        [ticketRequestId]: {
          ...ticket,
          isModifying: false
        }
      }
    });
    /*if (eventId && agencyCode) {
      this.props.getEventClientRequests(agencyCode, eventId);
    } */
  }

  onHoverChanges = ({ email }) => {
    const eventId = this.props.match.params && this.props.match.params.id;
    this.props.getClientHoverData(eventId, email);
  }

  onSaveAgencyRequest = (ticketRequestId) => {
    const eventId = this.props.match.params && this.props.match.params.id;
    const agencyCode = this.props.agencyCode;
    const ticketAgency = this.state.modifyingAgencyRows[ticketRequestId] || {};
    const bodyParams = {
      "evtech_numberofchildren": ticketAgency.AgencyChildrenToBeRequest,
      "evtech_numberofchaperones": ticketAgency.AgencyAdultToBeRequest,
      "evtech_totaltickets": ticketAgency.AgencyTotalTickets,
    }
    this.props.changeRequestAgencyRequest(ticketRequestId, bodyParams, agencyCode, eventId);
    this.setState({
      modifyingAgencyRows: {
        ...this.state.modifyingAgencyRows,
        [ticketRequestId]: {
          ...ticketAgency,
          isModifying: false
        }
      }
    });
  }

  onCancelChangeAgencyRequest = (ticketRequestId, actualAdultTickets, actualChildrenTickets, actualTotalTickets, changeReqDateArray) => {
    this.setState({ 'isChange': true });
    const { getChangeAgencyRequest, changeRequestAgencyRequest } = this.props;
    const eventId = this.props.match.params && this.props.match.params.id;
    if (changeReqDateArray) {
      changeReqDateArray.pop();
    }


    const agencyCode = this.props.agencyCode;
    getChangeAgencyRequest(ticketRequestId);
    changeRequestAgencyRequest(ticketRequestId, {
      "evtech_requeststatus": 807710002,
      "evtech_numberofchaperones": actualAdultTickets,
      "evtech_numberofchildren": actualChildrenTickets,
      "evtech_totaltickets": actualTotalTickets,
      "cr676_change_request_date_array": (changeReqDateArray && changeReqDateArray.length) ? JSON.stringify(changeReqDateArray) : null
    }, agencyCode, eventId);
  }

  onSaveAllocationRequest = ({ ticketRequestId }) => {
    const eventId = this.props.match.params && this.props.match.params.id;
    const agencyCode = this.props.agencyCode;
    const ticket = this.state.modifyAllocationRow[ticketRequestId] || {};
    const { eventDetails } = this.props;
    const bodyParams = {
      "cr676_children_allocate": ticket.childrenTicketAllocationToBeRequest,
      "cr676_adult_allocate": ticket.adultTicketAllocationToBeRequest,
      "cr676_total_allocated": ticket.totalTicketAllocationToBeRequest,
      "evtech_totalticketvalue_base": eventDetails.cr676_event_price,
      "evtech_totalticketvalue": (eventDetails.cr676_event_price) ? ticket.totalTicketAllocationToBeRequest * eventDetails.cr676_event_price : ticket.totalTicketAllocationToBeRequest
    }
    this.props.modifyTicketAllocationRequest(ticketRequestId, bodyParams, eventId, agencyCode);
    this.setState({
      modifyAllocationRow: {
        ...this.state.modifyAllocationRow,
        [ticketRequestId]: {
          ...ticket,
          isModifying: false
        }
      }
    });
  }

  onCancelAgencyRequest = async (ticketRequestId, index, clientRequestid) => {
    const ticketAgency = this.state.modifyingAgencyRows[ticketRequestId] || {};
    const eventId = this.props.match.params && this.props.match.params.id;
    const bodyParams = {
      "cr676_delete_status": true,
      "evtech_requeststatus": 807710003,
    }
    await this.props.updateBatchAgencyRequest(JSON.parse(clientRequestid), ticketRequestId, this.props.agencyCode, this.props.agencyGuid, eventId);

  }

  updateTicketStatus = ({ status, ticketRequestId, emailContent }) => {
    const eventId = this.props.match.params && this.props.match.params.id;
    const agencyCode = this.props.agencyCode;
    const body = {
      evtech_requeststatus: status === 'accept' ? 807710002 : 807710003
    }
    this.props.onSaveChanges(ticketRequestId, agencyCode, eventId, body,);
    if (status === 'reject') {
      // this.props.sendEmailOnChangeStatus(emailContent);
      this.props.sendEmailOnRejectClientTickets(emailContent)
    }

    const ticket = this.state.modifyingRows[ticketRequestId] || {};
    this.setState({
      modifyingRows: {
        ...this.state.modifyingRows,
        [ticketRequestId]: {
          ...ticket,
          isModified: false,
          status: body.evtech_requeststatus
        }
      }
    });
  }



  eventsData = () => {
    const { eventDetails, dynamic } = this.props

    const eventsReq = this.props.eventClientRequests ? this.props.eventClientRequests.map((req, index) => {
      const name = req.evtech_chaperonename ? req.evtech_chaperonename : '';
      const ticketRequestId = req.evtech_ticketrequestid;
      const totalTicketsRr = req.evtech_actualtotaltickets || 0;
      const totalAdultTicketsRr = req.evtech_actualchaperones || 0;
      const totalChildrenTicketsRr = req.evtech_actualchildren || 0;
      const ticketState = this.state.modifyingRows[ticketRequestId];
      const totalAdultTicketsTbs = parseInt(ticketState && ticketState.adultToBeRequest) || req.evtech_numberofchaperones;
      const totalChildrenTicketsTbs = parseInt(ticketState && ticketState.childrenToBeRequest) || req.evtech_numberofchildren;
      const status = displayStatus({ ticketStatus: ((ticketState && ticketState.status) || req.evtech_requeststatus), activeTab: this.state.activeTab });
      const isModified = ticketState && ticketState.isModified
      const totalTicketsTbs = (totalAdultTicketsTbs + totalChildrenTicketsTbs) || req.evtech_totaltickets;

      let emailContent = [
        {
          "to_email": req.emailaddress,
          "event_name": req.cr676_event_name,
          // "client_name": name,
          // "status": "Rejected",
          startdate: (eventDetails && eventDetails.evtech_startdate) ? moment.utc(eventDetails.evtech_startdate).format('lll') : 'N/A',
          agency: dynamic?.parentcustomerid_account?.name || 'N/A',
          business_unit: this.props.business_detail?.name
        }];
      return {
        ticketRequestId,
        name: name,
        email: req.emailaddress,
        totalTicketsRr: req.evtech_actualtotaltickets ? req.evtech_actualtotaltickets : 0,
        totalTicketsTbs: totalTicketsTbs,
        totalAdultTicketsRr: totalAdultTicketsRr,
        totalAdultTicketsTbs: totalAdultTicketsTbs,
        totalChildrenTicketsRr: totalChildrenTicketsRr,
        totalChildrenTicketsTbs: totalChildrenTicketsTbs,
        comment: req.evtech_notes ? req.evtech_notes : '-',
        status: status,
        action: <ActionButtons
        status={status}
        totalTicketsTbs={totalTicketsTbs}

        modifyStatus={isModified}
        ticketRequestId={ticketRequestId}
        isModifying={ticketState && ticketState.isModifying}
        onModify={this.modifyTicket}
        //  onModify={() => this.onModifyAllocation({ ticketRequestId, totalAdultTicketsTbs, totalChildrenTicketsTbs, totalTicketsTbs })}

         onModifyRow={() => this.modifyTicket({ ticketRequestId, totalTicketsTbs, totalAdultTicketsTbs, totalChildrenTicketsTbs })}
        onSaveChanges={this.onSaveChanges}
        onAccept={() => this.updateTicketStatus({ status: 'accept', ticketRequestId })}
        onReject={() => this.updateTicketStatus({ status: 'reject', ticketRequestId, emailContent })}
        />
      }
    }) : [];
    if (this.state.subheader) {
      eventsReq.unshift({
        name: '',
        email: '',
        TotalTickets: true,
        adultTickets: true,
        requestsReceived: true,
        cTickets: true,
        requestsToBeSent: true,
        comment: '',
        status: '',
        action: ''
      });
    } else {
      eventsReq.unshift({
        name: '',
        email: '',
        TotalTickets: '',
        adultTickets: '',
        requestsReceived: '',
        cTickets: '',
        requestsToBeSent: '',
        comment: '',
        status: '',
        action: ''
      });
    }
    return eventsReq;
  }

  onAllocate = ({ ticketRequestId, totalAdultTicketsTbs, totalChildrenTicketsTbs, totalTicketsTbs }) => {
    const { eventDetails, eventAgencyRequest } = this.props;
    const totalTicketValue = find(eventAgencyRequest, function (o) { return Number.isInteger(o.evtech_totalticketvalue) })

    let totalTicket = 1, singleTicketValue = 0
    if (totalTicketValue) {
      totalTicket = (totalTicketValue.cr676_confirmed_children_tickets && totalTicketValue.cr676_confirmed_adult_tickets) ? Number(totalTicketValue.cr676_confirmed_children_tickets) + Number(totalTicketValue.cr676_confirmed_adult_tickets) : (totalTicketValue.cr676_confirmed_children_tickets || totalTicketValue.cr676_confirmed_adult_tickets) ? totalTicketValue.cr676_confirmed_children_tickets || totalTicketValue.cr676_confirmed_adult_tickets : 1
      singleTicketValue = totalTicketValue.evtech_totalticketvalue / totalTicket
    }

    const body = {
      "evtech_assignmentreviewstatus": 807710001,
      "evtech_requeststatus": 807710002,
      "cr676_children_allocate": totalChildrenTicketsTbs,
      "cr676_adult_allocate": totalAdultTicketsTbs,
      "cr676_total_allocated": totalTicketsTbs,
      "evtech_totalticketvalue_base": singleTicketValue * totalTicketsTbs,
      "evtech_totalticketvalue": singleTicketValue * totalTicketsTbs,
      "evtech_confirmedtickets": totalTicketsTbs,
      "evtech_confirmeddate": moment(Date.now()).format('lll'),
      "evtech_quanityassigned": totalTicketsTbs,
      "evtech_ticketsassigned": totalTicketsTbs
    }
    this.setState({
      modifyAllocationRow: {
        ...this.state.modifyAllocationRow,
        [ticketRequestId]: {
          status: 476940003,
          totalTicketAllocationToBeRequest: totalTicketsTbs,
          adultTicketAllocationToBeRequest: totalAdultTicketsTbs,
          childrenTicketAllocationToBeRequest: totalChildrenTicketsTbs
        }
      }
    });
    const eventId = this.props.match.params && this.props.match.params.id;
    const agencyCode = this.props.agencyCode;
    this.props.allocateTicket(ticketRequestId, body, eventId, agencyCode);
  }

  unAllocate = ({ ticketRequestId }) => {
    const body = {
      "evtech_assignmentreviewstatus": 807710000,
      "evtech_requeststatus": 476940000,
      "evtech_totalticketvalue_base": 0.0,
      "evtech_totalticketvalue": 0.0,
      "evtech_confirmedtickets": null,
      "evtech_confirmeddate": null,
      "evtech_quanityassigned": null,
      "evtech_ticketsassigned": null
    }
    this.setState({
      modifyAllocationRow: {
        ...this.state.modifyAllocationRow,
        [ticketRequestId]: {
          status: 476940000,
          totalTicketAllocationToBeRequest: 0,
          adultTicketAllocationToBeRequest: 0,
          childrenTicketAllocationToBeRequest: 0
        }
      }
    });
    const eventId = this.props.match.params && this.props.match.params.id;
    const agencyCode = this.props.agencyCode;
    this.props.allocateTicket(ticketRequestId, body, eventId, agencyCode);
  }

  allocationTabData = () => {
    // const nik = this.props.eventAgencyRequest
    const eventsReq = this.props.allocationTicketRequests ? this.props.allocationTicketRequests.map((req) => {

      const name = req.evtech_chaperonename ? req.evtech_chaperonename : '';
      const ticketRequestId = req.evtech_ticketrequestid;
      const totalTicketsRr = req.evtech_totaltickets || 0;
      const totalAdultTicketsRr = req.evtech_numberofchaperones || 0;
      const totalChildrenTicketsRr = req.evtech_numberofchildren || 0;
      const ticketState = this.state.modifyAllocationRow[ticketRequestId];
      const totalTicketsTbs = (ticketState && ticketState.totalTicketAllocationToBeRequest) || req.cr676_total_allocated || 0;
      const totalAdultTicketsTbs = (ticketState && ticketState.adultTicketAllocationToBeRequest) || req.cr676_adult_allocate || 0;
      const totalChildrenTicketsTbs = (ticketState && ticketState.childrenTicketAllocationToBeRequest) || req.cr676_children_allocate || 0;
      const status = displayStatus({ ticketStatus: ((ticketState && ticketState.status) || req.evtech_requeststatus), activeTab: this.state.activeTab });
      const remainingTickets = (this.props.totalAgencyTicket && this.props.totalAgencyTicket.value && this.props.totalAgencyTicket.value[0].agency_total_tickets) - (this.props.totalReceivedAndAllocatedTickets && this.props.totalReceivedAndAllocatedTickets.value && this.props.totalReceivedAndAllocatedTickets.value[0].total_allocated);
      return {
        ticketRequestId,
        name: name,
        email: req.emailaddress,
        totalTicketsRr,
        totalTicketsTbs,
        totalAdultTicketsRr,
        totalAdultTicketsTbs,
        totalChildrenTicketsRr,
        totalChildrenTicketsTbs,
        status: status,
        distribution: this.props.eventAgencyRequest ? this.props.eventAgencyRequest?.evtech_ticketdistribution : 'null',

        action: ((status === "Shared") ? '' : <TicketAllocationActionButtons
          ticketRequestId={ticketRequestId}
          status={status}
          remainingTickets={remainingTickets}
          totalTicketsTbs={totalTicketsTbs}
          isModifying={ticketState && ticketState.isModifying}
          onModifyRow={this.modifyTicket}
          onSaveAllocationRequest={this.onSaveAllocationRequest}
          onAllocate={() => this.onAllocate({ ticketRequestId, totalAdultTicketsTbs, totalChildrenTicketsTbs, totalTicketsTbs })}
          unAllocate={() => this.unAllocate({ ticketRequestId })}
          onModify={() => this.onModifyAllocation({ ticketRequestId, totalAdultTicketsTbs, totalChildrenTicketsTbs, totalTicketsTbs })}
        />)
      }
    }) : [];

    if (this.state.ticketSubheader) {
      eventsReq.unshift({
        name: '',
        email: '',
        TotalTickets: true,
        adultTickets: true,
        requestSent: true,
        cTickets: true,
        allocated: true,
        comment: '',
        status: '',
        action: ''
      });
    } else {
      eventsReq.unshift({
        name: '',
        email: '',
        TotalTickets: '',
        adultTickets: '',
        requestSent: '',
        cTickets: '',
        allocated: '',
        comment: '',
        status: '',
        action: ''
      });
    }

    return eventsReq;
  }

  agencyData = () => {
    const sortedAgencyRequest = this.props.eventAgencyRequest ? this.props.eventAgencyRequest.sort((a, b) => new Date(b.createdon) - new Date(a.createdon)) : '';
    const agencyReq = sortedAgencyRequest ? sortedAgencyRequest.map((req, index) => {
      const ticketRequestId = req.evtech_ticketrequestid;
      const name = req.cr676_requested_by_name ? req.cr676_requested_by_name : req.evtech_agencyid ? req.evtech_agencyid.name : '';
      const ticketAgencyState = this.state.modifyingAgencyRows[ticketRequestId];
      const totalAgencyReq = (ticketAgencyState && ticketAgencyState.AgencyTotalTickets) ? ticketAgencyState.AgencyTotalTickets : req.evtech_totaltickets;
      const totalAdultAgencyReq = (ticketAgencyState && ticketAgencyState.AgencyAdultToBeRequest) || req.evtech_numberofchaperones;
      const totalChildrenAgencyReq = (ticketAgencyState && ticketAgencyState.AgencyChildrenToBeRequest) || req.evtech_numberofchildren;
      const totalAdultAgencyReceived = req.cr676_confirmed_adult_tickets || '-'
      const totalChildrenAgencyReceived = req.cr676_confirmed_children_tickets || '-'
      const totalAgencyReceived = (req.cr676_confirmed_children_tickets && req.cr676_confirmed_adult_tickets) ? Number(req.cr676_confirmed_children_tickets) + Number(req.cr676_confirmed_adult_tickets) : (req.cr676_confirmed_children_tickets || req.cr676_confirmed_adult_tickets) ? req.cr676_confirmed_children_tickets || req.cr676_confirmed_adult_tickets : "-"

      const actualAdultTickets = req.evtech_actualchaperones || '';
      const actualChildrenTickets = req.evtech_actualchildren || '';
      const actualTotalTickets = req.evtech_actualtotaltickets || '';
      const status = displayAgencyStatus({ ticketStatus: req.evtech_requeststatus, activeTab: this.state.activeTab });
      const popupData = {
        createdon: moment(req && req.createdon).format('lll') || '',
        actualTotalTickets: actualTotalTickets,
        actualAdultTickets: actualAdultTickets,
        actualChildrenTickets: actualChildrenTickets,
        requestDate: req.cr676_change_request_date ? moment(req.cr676_change_request_date).format('lll') || '' : '',
        reqTotalTickets: totalAgencyReq,
        reqAdultTickets: totalAdultAgencyReq,
        reqChildrenTickets: totalChildrenAgencyReq,
        changeReqDateArray: JSON.parse(req.cr676_change_request_date_array)
      };
      return {
        ticketRequestId,
        name: name,
        date: moment(req && req.createdon).format('lll') || '',
        totalAgencyReq,
        totalAdultAgencyReq,
        totalChildrenAgencyReq,

        totalAdultAgencyReceived,
        totalChildrenAgencyReceived,
        totalAgencyReceived,

        received: req.evtech_quanityassigned || 0,
        comments: req.evtech_comments,
        amount: req.evtech_totalticketvalue || 0,
        status: <AgencyPopover
          index={index}
          popupData={popupData}
          status={status}
        />,
        action: <ActionButtonsAgency
          ticketRequestId={ticketRequestId}
          index={index}
          status={displayStatus({ ticketStatus: req.evtech_requeststatus, activeTab: this.state.activeTab })}
          isEditing={ticketAgencyState && ticketAgencyState.isModifying}
          isModifying={displayStatus({ ticketStatus: (req.evtech_requeststatus), activeTab: this.state.activeTab }) !== 'Pending'}
          onModifyRow={(ticketRequestId) => {
            this.setState({
              modalRequest: true,
              requestevtech_ticketrequestid: req.evtech_ticketrequestid,
              requestConformTickets: req.evtech_quanityassigned,
              requestComments: req.evtech_comments,
              requestChildren: "",
              requestChaperones: "",
              requestActualTotalTickets: totalAgencyReq,
              requestActualChaperones: totalAdultAgencyReq,
              requestActualChildren: totalChildrenAgencyReq,
              changeReqDateArray: req.cr676_change_request_date_array
            })
          }}
          onCancelChangeAgencyRequest={(ticketRequestId) => { this.onCancelChangeAgencyRequest(ticketRequestId, actualAdultTickets, actualChildrenTickets, actualTotalTickets, JSON.parse(req.cr676_change_request_date_array)) }
          }
          onEditRow={(requestId) => {
            this.onEditAgencyRequest(requestId, totalAgencyReq, totalAdultAgencyReq, totalChildrenAgencyReq)
          }}
          onSaveAgencyRequest={this.onSaveAgencyRequest}
          onCancelAgencyRequest={(ticketRequestId, index) => { this.onCancelAgencyRequest(ticketRequestId, index, req.cr676_client_request_id) }}
        />
      }
    }) : [];
    if (this.state.agencySubheader) {
      agencyReq.unshift({
        name: '',
        date: '',
        requested: true,
        received: '',
        comments: '',
        status: '',
        action: ''
      });
    } else {
      agencyReq.unshift({
        name: '',
        date: '',
        requested: '',
        received: '',
        comments: '',
        status: '',
        action: ''
      });
    }
    return agencyReq;
  }

  componentDidUpdate() {
    const { get_event_categories } = this.props
    const { get_event_categories_State } = this.state

    if (get_event_categories !== get_event_categories_State) {
      let getEventCategoriesList = []
      get_event_categories.length > 0 && get_event_categories.map((event) => {
        getEventCategoriesList.push({
          value: event.code,
          label: event.name
        })
        return true
      })

      this.setState({
        get_event_categories_State: get_event_categories,
        getEventCategories: getEventCategoriesList
      })
    }

  }

  handleChange = (name, value) => {
    const { validationChangeReq } = this.state;
    this.setState((prevState) => ({
      ...prevState, // keep all other key-value pairs
      [name]: value, // update the value of specific key
      validationChangeReq: validateChangeReq(validationChangeReq, name, value)
    }));
  }

  onChangeHandler = (name, value) => {
    const { validationSharedModel } = this.state;
    this.setState((prevState) => ({
      ...prevState, // keep all other key-value pairs
      [name]: value, // update the value of specific key
      validationSharedModel: validateSharedModel(validationSharedModel, name, value)
    }));
  }

  checkValidationSharedModel = () => {
    const { validationSharedModel } = this.state;
    const check = checkValid(validationSharedModel);
    if (check) {
      return true;
    }
    return false;
  };

  checkValidationChangeReq = () => {
    const { validationChangeReq } = this.state;
    const check = checkValid(validationChangeReq);
    if (check) {
      return true;
    }
    return false;
  };
  submitChangeRequest = () => {
    const { changeRequestAgencyRequest, saveChangeAgencyRequest } = this.props
    const { requestComments, requestChildren, requestChaperones, requestConformTickets, changeReqDateArray, requestevtech_ticketrequestid, requestActualTotalTickets, requestActualChaperones, requestActualChildren } = this.state;
    this.setState({ 'isChange': true });
    const totalTicket = (!isNaN(Number.parseInt(requestChildren, 10)) ? Number.parseInt(requestChildren, 10) : 0) + (!isNaN(Number.parseInt(requestChaperones, 10)) ? Number.parseInt(requestChaperones, 10) : 0);
    const eventId = this.props.match.params && this.props.match.params.id;
    const agencyCode = this.props.agencyCode;
    let currentDate = new Date().toISOString();
    let date = [];
    let changeReqDate = changeReqDateArray ? JSON.parse(changeReqDateArray) : [];
    if (changeReqDate && changeReqDate.length) {
      changeReqDate.push('' + currentDate + '')
    } else {
      date.push(currentDate)
    }

    changeRequestAgencyRequest(requestevtech_ticketrequestid, {
      "evtech_requeststatus": 476940001,
      "evtech_actualtotaltickets": requestActualTotalTickets,
      "evtech_actualchaperones": requestActualChaperones,
      "evtech_actualchildren": requestActualChildren,
      "evtech_numberofchaperones": requestChaperones,
      "evtech_numberofchildren": requestChildren,
      "evtech_totaltickets": totalTicket,
      "evtech_comments": requestComments,
      "cr676_change_request_date_array": (changeReqDate && changeReqDate.length) ? JSON.stringify(changeReqDate) : JSON.stringify(date)
    }, agencyCode, eventId);

    let data = {
      "evtech_requestedchange": "Adults: " + requestChaperones + ", Children: " + requestChildren + ", Total:" + totalTicket + "",
      "evtech_ticketrequestid@odata.bind": "evtech_ticketrequests(" + requestevtech_ticketrequestid + ")",
      "evtech_reasonforchange": requestComments
    };
    saveChangeAgencyRequest(data);
  }



  submitGenerateRequest = ({ comment }) => {
    const eventId = this.props.match.params && this.props.match.params.id;
    const agencyCode = this.props.agencyCode;
    const agencyId = this.props.guid;
    const adultTickets = this.state.modal.data.evtech_numberofchaperones;
    const childrenTickets = this.state.modal.data.evtech_numberofchildren;
    const totalTickets = adultTickets + childrenTickets;
    const ticketIds = this.state.modal.data.ticketIds;
    const eventName = this.props.eventDetails.evtech_name;
    const emailSentParams = { 'content': this.state.modal.data && this.state.modal.data.emailContent };
    const bodyParams = {
      "cr676_agency_code": agencyCode,
      "evtech_comments": comment,
      "evtech_numberofchildren": childrenTickets,
      "evtech_numberofchaperones": adultTickets,
      "evtech_totaltickets": totalTickets,
      "cr676_client_request_id": JSON.stringify(ticketIds),
      "evtech_eventid@odata.bind": `evtech_events(${eventId})`,
      "evtech_agencyid@odata.bind": `accounts(${agencyId})`,
      "cr676_event_name": eventName,
      "cr676_requested_by_name": this.props.user.data.name
    }
    if (this.props.user && this.props.user.data.systemUserId) {
      bodyParams["ownerid@odata.bind"] = `systemusers(${this.props.user.data.systemUserId})`
    }

    if (this.props.dynamic?.contactid)
      bodyParams["new_PersonRequesting@odata.bind"] = `contacts(${this.props.dynamic.contactid})`

    this.props.generateTicketRequest(bodyParams, { ticketIds, agencyCode, eventId }, emailSentParams);
    // this.startFetchingEventClientRequests();
    // const userId = this.props.dynamic && this.props.dynamic._owninguser_value;
    //this.props.getEventDetails(eventId);

    this.setState({ modal: { ...this.state.modal, isVisible: !this.state.modal.isVisible } })
  }



  submitAgencyGenerateRequest = async ({ comment, adultTickets, childrenTickets, totalTickets }) => {

    const eventId = this.props.match.params && this.props.match.params.id;
    const agencyCode = this.props.agencyCode;
    const agencyId = this.props.guid;
    const agencyGuid = this.props.agencyGuid;
    // const ticketIds = this.state.modal.data.ticketIds;
    const eventName = this.props.eventDetails.evtech_name;
    // const emailSentParams = { 'content': this.state.modal.data && this.state.modal.data.emailContent };

    const getId = localStorage.getItem('headerId')
    let formObject = {
      "evtech_chaperonename": this.props.user.data.name,
      "emailaddress": this.props.user.data.email,
      "cr676_agency_code": agencyCode,
      "evtech_notes": comment,
      "cr676_event_name": eventName,
      "evtech_numberofchildren": childrenTickets,
      "evtech_actualchildren": childrenTickets,
      "evtech_numberofchaperones": adultTickets,
      "evtech_actualchaperones": adultTickets,
      "evtech_eventid@odata.bind": `evtech_events(${eventId})`,
      "evtech_actualtotaltickets": totalTickets,
      "evtech_totaltickets": totalTickets,
      "cr676_children_allocate": childrenTickets,
      "cr676_adult_allocate": adultTickets,
      "cr676_total_allocated": totalTickets
    }

    const bodyParams = {
      "cr676_agency_code": agencyCode,
      "evtech_comments": comment,
      "evtech_numberofchildren": childrenTickets,
      "evtech_numberofchaperones": adultTickets,
      "evtech_totaltickets": totalTickets,
      // "cr676_client_request_id": JSON.stringify(ticketIds),
      "evtech_eventid@odata.bind": `evtech_events(${eventId})`,
      "evtech_agencyid@odata.bind": `accounts(${agencyId})`,
      "cr676_event_name": eventName,
      "cr676_requested_by_name": this.props.user.data.name,
      // "cr676_client_request_id": JSON.stringify([getId])
    }
    if (this.props.user && this.props.user.data.systemUserId) {
      bodyParams["ownerid@odata.bind"] = `systemusers(${this.props.user.data.systemUserId})`
    }
    if (this.props.dynamic?.contactid)
      bodyParams["new_PersonRequesting@odata.bind"] = `contacts(${this.props.dynamic.contactid})`
    //    this.props.generateTicketRequest(bodyParams1, { ticketIds: {}, agencyCode, eventId })
    this.props.generateAgencyTicketRequest(formObject, { ticketIds: {}, agencyCode, eventId }, true, bodyParams, agencyGuid, eventId)

    this.setState({ agencyTicketRequestModel: { ...this.state.agencyTicketRequestModel, isVisible: !this.state.agencyTicketRequestModel.isVisible } })

  }

  onCancelAgencyGenerateRequest = () => {
    this.setState({ agencyTicketRequestModel: { ...this.state.agencyTicketRequestModel, isVisible: false } })
  }

  allocatedTicket = () => {
    const ticketData = this.allocationTabData();
    let AllocatedTickets = ticketData.filter(function (e) {
      return e.status === 'Allocated';
    });
    return AllocatedTickets;
  }

  disableSharedBtn = () => {
    const ticketData = this.allocatedTicket();
    if (ticketData.length == 0) {
      return true;
    }
    return false;
  }

  changeToSharedRequest = () => {
    const { eventDetails, user, business_detail } = this.props
    // console.log('user', user)
    const { getEventCategories } = this.state
    const ticketData = this.allocatedTicket();
    // console.log('-------allocationTicketRequests', this.props.allocationTicketRequests)

    let emailData = {
      startDate: (eventDetails && eventDetails.evtech_startdate) ? moment.utc(eventDetails.evtech_startdate).format('lll') : 'N/A',
      category: getEventCategories.findIndex(data => Number.parseInt(data.value, 10) === eventDetails.evtech_eventcategory) !== -1 ? getEventCategories[getEventCategories.findIndex(data => Number.parseInt(data.value, 10) === eventDetails.evtech_eventcategory)].label : "N/A",
      ageRange: eventDetails?.evtech_agerange ? eventDetails.evtech_agerange : 'N/A',
      address: [eventDetails.evtech_addressline1, eventDetails.evtech_addressline2, eventDetails.evtech_addressline3].join((' ')) + eventDetails?.evtech_city,
      eventName: eventDetails?.evtech_name || 'N/A',
      // agencyName: user?.data?.name || 'N/A',
      agencyName: this.props.dynamic?.parentcustomerid_account?.name,
      business_unit: this.props.business_detail?.name,
      evtech_description: eventDetails?.evtech_description,
      evtech_ticketdistribution: eventDetails?.evtech_eventcategory

    }

    this.props.updateBatchSharedRequest(ticketData, this.state.subject, this.state.emailbody, emailData);
  }

  setAllocationTab = () => {
    const eventId = this.props.match.params && this.props.match.params.id;
    if (this.props.agencyCode) {
      this.props.getTicketAllocationRequest(this.props.agencyCode, eventId);
      this.props.getAgencyTotalTickets(this.props.agencyGuid, eventId);
    }
  }

  isGenerateButtonEnable = () => {
    const eventClientRequests = this.props.eventClientRequests ? this.props.eventClientRequests : [];
    for (let i = 0; i < eventClientRequests.length; i++) {
      const req = eventClientRequests[i];
      const ticketState = this.state.modifyingRows[req.evtech_ticketrequestid];
      const status = (ticketState && ticketState.status) || req.evtech_requeststatus;
      if (status === 807710002) {
        return true
      }
    }

    return false
  }

  toggle = (tab) => {
    const eventId = this.props.match.params && this.props.match.params.id;
    if (tab === '1') {
      if (this.props.agencyCode && this.props.agencyGuid) {
        this.props.getEventClientRequests(this.props.agencyCode, eventId);
        this.props.getAgencyTotalTickets(this.props.agencyGuid, eventId);
        this.props.getRequestTotalTickets(this.props.agencyCode, eventId)
      }
    }

    if (tab === '2') {
      if (this.props.agencyGuid && this.props.agencyCode) {
        this.props.getEventAgencyRequest(this.props.agencyGuid, eventId);
        this.props.getTotalReceivedAndAllocatedTickets(this.props.agencyCode, eventId);
        this.props.getRequestTotalTickets(this.props.agencyCode, eventId);
      }
    }

    if (tab === '3') {
      this.setAllocationTab();
    }

    if (this.state.activeTab !== tab) {
      /* if (tab === '3') {
         this.setAllocationTab();
       } */
      this.setState({
        activeTab: tab
      })
    }
  }

  rowClasses = (row, rowIndex) => {
    if (row.email) {
      let count = this.props.eventClientRequests.filter(function (e) {
        return e.emailaddress === row.email;
      });
      if (count.length > 1) {
        return "multiple_req_color"
        // return {backgroundColor: '#e8e8e8' };
      } else {
        return "single_req_color"
      }

    }
  };

  columns = [
    {
      dataField: 'name',
      text: 'Name',
      // sort: true,
      filter: textFilter({
        comparator: Comparator.LIKE,
        placeholder: "Name",
        getFilter: (filter) => {
          this.setState.setNameFilter = filter;
        }

      }),
      headerFormatter: this.nameFormatter,
      headerEvents: {
        onClick: async (e, column, columnIndex) => {
          this.setState({ subheader: !this.state.subheader }, () => {
            if (this.state.subheader) {
              this.setState.setNameFilter('');
            }
          })
          this.setState({ columnHeaderElement: { name: !this.state.columnHeaderElement.name, filter: !this.state.columnHeaderElement.filter } })

        }
      }
    },
    {
      dataField: 'email',
      text: 'Email Address',
      filter: textFilter({
        comparator: Comparator.LIKE,
        getFilter: (filter) => {
          this.setState.setEmailFilter = filter;
        },
        placeholder: 'Email Address',
      }),
      headerFormatter: this.emailFormatter,
      headerEvents: {
        onClick: async (e, column, columnIndex) => {
          this.setState({ subheader: !this.state.subheader }, () => {
            if (this.state.subheader) {
              this.setState.setEmailFilter('');
            }
          })
          this.setState({ columnEmailElement: { name: !this.state.columnEmailElement.name, filter: !this.state.columnEmailElement.filter } })
        }
      },
      headerStyle: { 'white-space': 'nowrap' }
    },
    {
      dataField: 'requestsReceived',  //adultTickets
      text: 'Requests Received',
      formatter: (col, row) => {
        if (row.requestsReceived == true) {    //row.adultTickets
          return this.tripleHeader({
            string1: "Adult",
            string2: "Children",
            string3: "Total"
          });
          // return this.doubleHeader({
          //   string1: "Request",
          //   string2: "Received",
          //   string3: "to be sent"
          // });
        }
        else {
          const ticketState = this.state.modifyingRows[row.ticketRequestId];
          return this.agencyRequestsReceived(row.totalAdultTicketsRr, row.totalChildrenTicketsRr, row.totalTicketsRr, row.ticketRequestId, ticketState && ticketState.isModifying);
          // row.totalAdultTicketsTbs, row.ticketRequestId, ticketState && ticketState.isModifying);
        }
      },
    },
    {
      dataField: 'requestsToBeSent',  //cTickets
      text: 'Requests To Be Sent',
      formatter: (col, row) => {
        if (row.requestsToBeSent == true) {  //cTickets
          return this.tripleHeader({
            string1: "Adult",
            string2: "Children",
            string3: "Total"
          });
        }
        else {
          const ticketState = this.state.modifyingRows[row.ticketRequestId];
         return this.totalChildrenTicketAllow(row.totalAdultTicketsTbs, row.totalChildrenTicketsTbs, row.totalTicketsTbs, ticketState && ticketState.isModifying, row.ticketRequestId);
          return this.agencyRequestsToBeSent(row.totalAdultTicketsTbs, row.totalChildrenTicketsTbs, row.totalTicketsTbs)
        }
      },
    },
    // {
    //   dataField: 'TotalTickets',
    //   text: 'Total Experiences',
    //   formatter: (col, row) => {
    //     if (row.TotalTickets == true) {
    //       return this.doubleHeader({
    //         string1: "Request",
    //         string2: "Received",
    //         string3: "to be sent"
    //       });
    //     }
    //     else {
    //       return this.totalAdultTicketAllow(row.totalTicketsRr, (row.totalAdultTicketsTbs + row.totalChildrenTicketsTbs));
    //     }
    //   },
    // },
    {
      dataField: 'comment',
      text: 'Comments',
      sort: true,
      headerStyle: { 'white-space': 'nowrap' }
    },
    {
      dataField: 'status',
      text: 'Status',
      //	formatter: cell => selectOptions[cell],
      /*filter: selectFilter({
      options: selectOptions
     }), */
      filter: textFilter({
        comparator: Comparator.LIKE,
        placeholder: "Status",
        getFilter: (filter) => {
          this.setState.setClientStatusFilter = filter;
        }
      }),
      headerFormatter: this.columnStatusElement,
      headerEvents: {
        onClick: async (e, column, columnIndex) => {
          this.setState({ subheader: !this.state.subheader }, () => {
            if (this.state.subheader) {
              this.setState.setClientStatusFilter('');
            }
          })
          this.setState({ columnStatusElement: { name: !this.state.columnStatusElement.name, filter: !this.state.columnStatusElement.filter } })
        }
      }
    },
    {
      dataField: 'action',
      text: 'Action'
    }
  ];


  nameFormatter = (column, colIndex, { sortElement, filterElement }) => {
    const { columnHeaderElement } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {columnHeaderElement.filter ? <>  {filterElement} <span> <i class="fa fa-window-close" aria-hidden="true"></i> </span> </> : ''}
        {columnHeaderElement.name ? <span>  {column.text}  <i class="fa fa-search" aria-hidden="true"></i> </span> : ''}
        { sortElement}
      </div>
    );
  }

  emailFormatter = (column, colIndex, { sortElement, filterElement }) => {
    const { columnEmailElement } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {columnEmailElement.filter ? <>{filterElement} <span> <i class="fa fa-window-close" aria-hidden="true"></i> </span> </> : ''}
        {columnEmailElement.name ? <span>  {column.text}  <i class="fa fa-search" aria-hidden="true"></i> </span> : ''}
        { sortElement}
      </div>
    );
  }
  columnStatusElement = (column, colIndex, { sortElement, filterElement }) => {
    const { columnStatusElement } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {columnStatusElement.filter ? <> {filterElement}<span> <i class="fa fa-window-close" aria-hidden="true"></i> </span> </> : ''}
        {columnStatusElement.name ? <span>  {column.text}  <i class="fa fa-search" aria-hidden="true"></i> </span> : ''}
        { sortElement}
      </div>
    );
  }

  /*const clearNameFiler =  async (filter) => {
         this.setState.filter('');
  }; */



  agencyNameFormatter = (column, colIndex, { sortElement, filterElement }) => {
    const { agencyNameElement } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {agencyNameElement.filter ? <>{filterElement} <span> <i class="fa fa-window-close" aria-hidden="true"></i> </span> </> : ''}
        {agencyNameElement.name ? <span>  {column.text}  <i class="fa fa-search" aria-hidden="true"></i> </span> : ''}
        { sortElement}
      </div>
    );
  }

  agencyStatusFormatter = (column, colIndex, { sortElement, filterElement }) => {
    const { agencyStatusElement } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {agencyStatusElement.filter ? <> {filterElement} <span> <i class="fa fa-window-close" aria-hidden="true"></i> </span> </> : ''}
        {agencyStatusElement.name ? <span>  {column.text}  <i class="fa fa-search" aria-hidden="true"></i> </span> : ''}
        { sortElement}
      </div>
    );
  }
  columnAgency = [
    {
      dataField: 'name',
      text: 'Volunteer Name',
      filter: textFilter({
        placeholder: 'Volunteer Name',
        comparator: Comparator.LIKE,
        getFilter: (filter) => {
          this.setState.setAgencyNameFilter = filter;
        }
      }),
      headerFormatter: this.agencyNameFormatter,
      headerEvents: {
        onClick: async (e, column, columnIndex) => {
          this.setState({ agencySubheader: false });
          this.setState({ agencySubheader: !this.state.agencySubheader }, () => {
            if (this.state.agencySubheader) {
              this.setState.setAgencyNameFilter('');
            }
          })
          this.setState({ agencyNameElement: { name: !this.state.agencyNameElement.name, filter: !this.state.agencyNameElement.filter } })
        }
      }
    },
    {
      dataField: 'date',
      text: 'Date & time',
      sort: true
    },
    {
      dataField: 'requested',
      text: 'Requested',
      formatter: (col, row) => {

        if (row.requested == true) {
          return this.tripleHeader({
            string1: "Adult",
            string2: "Children",
            string3: "Total"
          });
        }
        else {
          const ticketState = this.state.modifyingAgencyRows[row.ticketRequestId];
          return this.totalAgencyAllow(row.totalAdultAgencyReq, row.totalChildrenAgencyReq, row.totalAgencyReq, ticketState && ticketState.isModifying, row.ticketRequestId);
        }
      },
    },
    {
      dataField: 'received',
      text: 'Received',
      formatter: (col, row) => {
        if (row.requested == true) {
          return this.singleHeader({
            // string1: "Adult",
            // string2: "Children",
            string3: "Total"
          });
        }
        else {
          return row.received;
          // return this.totalAgencyAllow(row.totalAdultAgencyReceived, row.totalChildrenAgencyReceived, row.totalAgencyReceived, false, row.ticketRequestId, true);
        }
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (col, row) => { return (col != null) ? numberFormat(col) : "" },
      sort: true
    },
    {
      dataField: 'comments',
      text: 'Comments',
      sort: true
    },
    {
      dataField: 'status',
      text: 'Status',
      /*filter: selectFilter({
  onFilter: (filterVal, data) => {
    return data.filter(item => (item.status && item.status.props.status == filterVal));
   },
      options: selectAgencyOptions
     }) */

      filter: textFilter({
        placeholder: 'Status',
        onFilter: (filterVal, data) => {
          let pattern = new RegExp(filterVal, 'i');
          if (filterVal) {
            return data.filter(item => (item.status && new RegExp(pattern).test('' + item.status.props.status + ''))
            )
          }
        },
        getFilter: (filter) => {
          this.setState.setAgencyStatusFilter = filter;
        }
      }),
      headerFormatter: this.agencyStatusFormatter,
      headerEvents: {
        onClick: async (e, column, columnIndex) => {
          this.setState({ agencySubheader: false })
          this.setState({ agencySubheader: !this.state.agencySubheader }, () => {
            if (this.state.agencySubheader) {
              this.setState.setAgencyStatusFilter('');
            }
          })
          this.setState({ agencyStatusElement: { name: !this.state.agencyStatusElement.name, filter: !this.state.agencyStatusElement.filter } })
        }
      },
    },
    {
      dataField: 'action',
      text: 'Action',
    }
  ];

  ticketNameFormatter = (column, colIndex, { sortElement, filterElement }) => {
    const { ticketNameElement } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {ticketNameElement.filter ? <> {filterElement} <span> <i class="fa fa-window-close" aria-hidden="true"></i> </span> </> : ''}
        {ticketNameElement.name ? <span>  {column.text}  <i class="fa fa-search" aria-hidden="true"></i> </span> : ''}
        { sortElement}
      </div>
    );
  }

  ticketStatusFormatter = (column, colIndex, { sortElement, filterElement }) => {
    const { ticketStatusElement } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {ticketStatusElement.filter ? <> {filterElement} <span> <i class="fa fa-window-close" aria-hidden="true"></i> </span> </> : ''}
        {ticketStatusElement.name ? <span>  {column.text}  <i class="fa fa-search" aria-hidden="true"></i> </span> : ''}
        { sortElement}
      </div>
    );
  }

  ticketEmailFormatter = (column, colIndex, { sortElement, filterElement }) => {
    const { ticketEmailElement } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {ticketEmailElement.filter ? <> {filterElement} <span> <i class="fa fa-window-close" aria-hidden="true"></i> </span> </> : ''}
        {ticketEmailElement.name ? <span>  {column.text}  <i class="fa fa-search" aria-hidden="true"></i> </span> : ''}
        { sortElement}
      </div>
    );
  }
  allocateTicketColumns = [
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter({
        placeholder: 'Name',
        getFilter: (filter) => {
          this.setState.setTicketNameFilter = filter;
        },
        comparator: Comparator.LIKE
      }),
      headerFormatter: this.ticketNameFormatter,
      headerEvents: {
        onClick: async (e, column, columnIndex) => {
          this.setState({ ticketSubheader: !this.state.ticketSubheader }, () => {
            if (this.state.ticketSubheader) {
              this.setState.setTicketNameFilter('');
            }
          })
          this.setState({ ticketNameElement: { name: !this.state.ticketNameElement.name, filter: !this.state.ticketNameElement.filter } })
        }
      },


    },
    {
      dataField: 'email',
      text: 'Email Address',
      filter: textFilter({
        placeholder: 'Email Address',
        comparator: Comparator.LIKE,
        getFilter: (filter) => {
          this.setState.setTicketEmailFilter = filter;
        }
      }),
      headerFormatter: this.ticketEmailFormatter,
      headerEvents: {
        onClick: async (e, column, columnIndex) => {
          this.setState({ ticketSubheader: !this.state.ticketSubheader }, () => {
            if (this.state.ticketSubheader) {
              this.setState.setTicketEmailFilter('');
            }
          })
          this.setState({ ticketEmailElement: { name: !this.state.ticketEmailElement.name, filter: !this.state.ticketEmailElement.filter } })
        }
      }
    },
    {
      dataField: 'requestSent',  //adultTickets
      text: 'Requests Sent',
      formatter: (col, row) => {
        if (row.requestSent == true) {
          return this.allocationHeader({
            string1: "Adult",
            string2: "Children",
            string3: "Total"
          });
        }
        else {
          // const ticketState = this.state.modifyAllocationRow[row.ticketRequestId];
          // return this.totalAdultTicketAllocation(row.totalAdultTicketsRr, row.totalAdultTicketsTbs, ticketState && ticketState.isModifying, row.ticketRequestId);
          return this.totalAdultTicketAllocation(row.totalAdultTicketsRr, row.totalChildrenTicketsRr, row.totalTicketsRr)
        }
      },
    },
    {
      dataField: 'allocated',   //cTickets
      text: 'Allocated',
      formatter: (col, row) => {
        if (row.cTickets == true) {
          return this.allocationHeader({
            string1: "Adult",
            string2: "Children",
            string3: "Total"
          });
        }
        else {
          const ticketState = this.state.modifyAllocationRow[row.ticketRequestId];
          return this.totalChildrenTicketAllocation(row.totalAdultTicketsTbs, row.totalChildrenTicketsTbs, row.totalTicketsTbs, ticketState && ticketState.isModifying, row.ticketRequestId);
        }
      },
    },
    // {
    //   dataField: 'TotalTickets',
    //   text: 'Total Experiences',
    //   formatter: (col, row) => {
    //     if (row.TotalTickets == true) {
    //       return this.allocationHeader({
    //         string1: "Request Sent",
    //         string2: "Allocated",
    //       });
    //     }
    //     else {
    //       const ticketState = this.state.modifyAllocationRow[row.ticketRequestId];
    //       return this.totalTicketAllocation(row.totalTicketsRr, row.totalTicketsTbs, ticketState && ticketState.isModifying, row.ticketRequestId);
    //     }
    //   },
    // },
    {
      dataField: 'status',
      text: 'Status',
      filter: textFilter({
        placeholder: 'Status',
        getFilter: (filter) => {
          this.setState.setTicketStatusFilter = filter;
        }
      }),
      headerFormatter: this.ticketStatusFormatter,
      headerEvents: {
        onClick: async (e, column, columnIndex) => {
          this.setState({ ticketSubheader: !this.state.ticketSubheader }, () => {
            if (this.state.ticketSubheader) {
              this.setState.setTicketStatusFilter('');
            }
          })
          this.setState({ ticketStatusElement: { name: !this.state.ticketStatusElement.name, filter: !this.state.ticketStatusElement.filter } })
        }
      }
      /* formatter: cell => selectAllocationOptions[cell],
          filter: selectFilter({
          options: selectAllocationOptions
         }) */
    },
    {
      dataField: 'action',
      text: 'Action'
    }
  ];

  render() {
    const {
      requestConformTickets,
      requestComments,
      requestChildren,
      requestChaperones,
      subject,
      emailbody,
      validationSharedModel: { subjectV, emailbodyV },
      validationChangeReq: { adultV, childrenV },
      images,
      getEventCategories,
      columnHeaderElement
    } = this.state;
    const { eventDetails, totalReceivedAndAllocatedTickets, totalAgencyTicket, totalRequestTicket, location } = this.props;
    const allocatedTickets = this.allocatedTicket();
    const allocatedTicketLength = allocatedTickets.length;
    const userPermission = this.props.user ? this.props.user.data && this.props.user.data.userPermission : null;

    return (
      <div className="dashboard">
        <Container fluid={true}>
          <div className="dashboard-heading">
            <span className="section-icon">
              <Icon name="calendar" />
            </span>
            <h4>{eventDetails && eventDetails.evtech_name}</h4>
          </div>
          <div className="event-details">
            {eventDetails && <EventPrimaryData
              eventDetails={eventDetails}
              totalReceivedAndAllocatedTickets={totalReceivedAndAllocatedTickets}
              totalAgencyTicket={totalAgencyTicket}
              totalRequestTicket={totalRequestTicket}
              userPermission={userPermission}
              image={get(location, 'state.eventImage', '')}
              getEventCategories={getEventCategories}
            />}
          </div>
          <div className="dashboard-tabs">
            <Nav tabs className="dashboard-tabs-nav">
              {userPermission &&
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Client Requests
                </NavLink>
                </NavItem>
              }
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  Agency Requests
                </NavLink>
              </NavItem>
              {userPermission &&
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '3' })}
                    onClick={() => {
                      this.toggle('3');
                    }}
                  >
                    Experiences Allocation
                </NavLink>
                </NavItem>
              }
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {userPermission &&
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="event-table-top">
                        <Row>
                          <Col xl="6">
                            <div className="request-outer">
                              <div className="request"><span className=""></span> Single Request</div>
                              <div className="request"><span className="multiple"></span> Multiple Requests</div>
                            </div>
                          </Col>
                          <Col xl="6">
                            <DefaultButton
                              disabled={!this.isGenerateButtonEnable()}
                              name="GENERATE REQUEST"
                              color="primary"
                              className="rt-btn ml-auto"
                              onClick={this.generateTicketRequest}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="event-table event-client-request">
                        <BootstrapTable rowClasses={this.rowClasses} bodyClasses="client-tbody" keyField='id' data={this.eventsData()} columns={this.columns} filter={filterFactory({ afterFilter })} pagination={paginationFactory()} />
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              }
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <div className="event-table-top">
                      <Row>
                        <Col xl="6">

                        </Col>
                        <Col xl="6">
                          <DefaultButton
                            disabled={this.isGenerateButtonEnable()}
                            name="GENERATE REQUEST"
                            color="primary"
                            className="rt-btn ml-auto"
                            onClick={this.agencyGenerateTicketRequest}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="event-table event-agency-request">
                      <BootstrapTable keyField='id' data={this.agencyData()} columns={this.columnAgency} filter={filterFactory({ afterFilter })} pagination={paginationFactory()} />

                    </div>
                  </Col>
                </Row>
              </TabPane>
              {userPermission &&
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <div className="event-table-top">
                        <Row>
                          <Col md={{ size: 6, offset: 6 }}>

                            <DefaultButton
                              disabled={this.disableSharedBtn()}
                              name="Share Experiences"
                              color="primary"
                              className="rt-btn"
                              onClick={this.sharedTicketRequest}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="event-table event-ticket-allocation">
                        <BootstrapTable keyField='id' data={this.allocationTabData()} filter={filterFactory({ afterFilter })} columns={this.allocateTicketColumns} pagination={paginationFactory()} />

                      </div>
                    </Col>
                  </Row>
                </TabPane>
              }
            </TabContent>
          </div>
        </Container>
        {/*  */}
        {this.state.modal.isVisible ?
          <GenerateTicketRequestModal
            data={this.state.modal.data}
            onSubmitGenerateRequest={this.submitGenerateRequest}
            onCancel={this.generateTicketRequest} />
          : ""}

        {this.state.agencyTicketRequestModel.isVisible ?
          <GenerateTicketRequestModalLimited
            onSubmitGenerateRequest={this.submitAgencyGenerateRequest}
            onCancel={this.onCancelAgencyGenerateRequest} />
          : ""}
        <Modal isOpen={this.state.modalRequest} toggle={this.modalToggleRequest} className="event-popup modal-dialog-centered">
          <ModalHeader toggle={this.modalToggleRequest}>Request Change</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Row>
                <Col sm="12" md={{ size: 12 }}>
                  <label>Current Requests Received</label>
                  <DefaultInput
                    name="requestConformTickets"
                    onChange={(event) => this.handleChange(event.target.name, event.target.value)}
                    value={requestConformTickets}
                    type="number"
                    disabled
                    placeholder="Current Receieved"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12" md={{ size: 12 }}>
                  <label>Adult Required</label>
                  <DefaultInput
                    name="requestChaperones"
                    validation={adultV}
                    onChange={(event) => this.handleChange(event.target.name, event.target.value)}
                    value={requestChaperones}
                    type="number"
                    placeholder="Adult Required"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12" md={{ size: 12 }}>
                  <label>Children Required</label>
                  <DefaultInput
                    name="requestChildren"
                    validation={childrenV}
                    onChange={(event) => this.handleChange(event.target.name, event.target.value)}
                    value={requestChildren}
                    type="number"
                    placeholder="Children Required"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12" md={{ size: 12 }}>
                  <label>Total Required</label>
                  <DefaultInput
                    name=""
                    value={(!isNaN(Number.parseInt(requestChildren, 10)) ? Number.parseInt(requestChildren, 10) : 0) + (!isNaN(Number.parseInt(requestChaperones, 10)) ? Number.parseInt(requestChaperones, 10) : 0)}
                    disabled
                    type="number"
                    placeholder="Total Required"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="12" md={{ size: 12 }}>
                  <label>Comments</label>
                  <DefaultInput
                    name="requestComments"
                    onChange={(event) => this.handleChange(event.target.name, event.target.value)}
                    value={requestComments}
                    type="text"
                    placeholder="Comments"
                  />
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.submitChangeRequest} disabled={this.checkValidationChangeReq()}>Submit</Button>{' '}
            <Button color="secondary" onClick={this.modalToggleRequest}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalSharedRequest} toggle={this.modalSharedToggleRequest} className="event-popup modal-dialog-centered">
          <ModalHeader toggle={this.modalSharedToggleRequest}>Share Experiences</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="12 mb-2" md={{ size: 12 }}>
                Allocated Experiences: {allocatedTicketLength}
              </Col>
            </Row>
            <FormGroup>
              <Row>
                <Col sm="12" md={{ size: 12 }}>
                  <DefaultInput
                    name="subject"
                    onChange={(event) => this.onChangeHandler(event.target.name, event.target.value)}
                    value={subject}
                    validation={subjectV}
                    type="text"
                    placeholder="Subject"
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm="12" md={{ size: 12 }}>
                  <DefaultInput
                    name="emailbody"
                    onChange={(event) => this.onChangeHandler(event.target.name, event.target.value)}
                    value={emailbody}
                    validation={emailbodyV}
                    type="textarea"
                    placeholder="Additional Notes"
                  />
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={this.checkValidationSharedModel()} onClick={this.changeToSharedRequest} >Submit</Button>{' '}
            <Button color="secondary" onClick={this.modalSharedToggleRequest}>Cancel</Button>
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dynamic: state.authReducer.dynamic_user_detail,
    agencyGuid: state.authReducer.user && state.authReducer.user.data.guid,
    agencyCode: state.authReducer.user && state.authReducer.user.data.agency_code && state.authReducer.user.data.agency_code,
    guid: state.authReducer.user && state.authReducer.user.data.guid,
    eventDetails: state.eventReducer.eventDetails,
    eventClientRequests: state.eventReducer.clientRequests && state.eventReducer.clientRequests.value,
    allocationTicketRequests: state.eventReducer.ticketAllocationRequests && state.eventReducer.ticketAllocationRequests.value,
    eventAgencyRequest: state.eventReducer.agencyRequests && state.eventReducer.agencyRequests.value,
    agencyChangeRequests: state.eventReducer.agencyChangeRequests,
    generateTicketRequest: state.eventReducer.generateTicketRequest,
    insertKey: state.eventReducer.insertKey,
    allocateTicket: state.eventReducer.allocateTicket,
    eventName: state.eventReducer.eventDetails && eventDetails.evtech_name,
    totalReceivedAndAllocatedTickets: state.eventReducer.totalReceivedAndAllocatedTickets,
    updateSharedTicketAllocation: state.eventReducer.updateSharedTicketAllocation,
    user: state.authReducer.user,
    totalAgencyTicket: state.eventReducer.totalAgencyTicket,
    totalRequestTicket: state.eventReducer.totalRequestTicket,
    get_event_categories: state.eventReducer.get_event_categories,
    business_detail: state.authReducer?.dynamic_user_business_detail
  }
};

const mapDispatchToProps = (dispatch) => ({
  getEventDetails: (eventId) => dispatch(getEventDetails(eventId)),
  getEventClientRequests: (agencyId, eventId) => dispatch(getEventClientRequests(agencyId, eventId)),
  getEventAgencyRequest: (agencyGuid, eventId) => dispatch(getEventAgencyRequest(agencyGuid, eventId)),
  getTicketAllocationRequest: (agencyCode, eventId) => dispatch(getTicketAllocationRequest(agencyCode, eventId)),
  onSaveChanges: (ticketRequestId, agencyCode, eventId, body) => dispatch(onSaveTicketAllocated(ticketRequestId, agencyCode, eventId, body)),
  changeRequestAgencyRequest: (ticketId, params, agencyCode, eventId) => dispatch(changeRequestAgencyRequest(ticketId, params, agencyCode, eventId)),
  updateBatchAgencyRequest: (clientRequestid, ticketRequestId, agencyCode, agencyGuid, eventId) => dispatch(updateBatchAgencyRequest(clientRequestid, ticketRequestId, agencyCode, agencyGuid, eventId)),
  generateTicketRequest: (body, opts, emailSentParams) => dispatch(generateTicketRequest(body, opts, emailSentParams)),
  generateAgencyTicketRequest: (body, opts, emailSentParams, firstCall, bodyParams, agencyGuid, eventId) => dispatch(generateAgencyTicketRequest(body, opts, emailSentParams, firstCall, bodyParams, agencyGuid, eventId)),
  insertKey: (ticketId, body) => dispatch(insertKey(ticketId, body)),
  allocateTicket: (ticketId, body, eventId, agencyCode) => dispatch(allocateTicket(ticketId, body, eventId, agencyCode)),
  modifyTicketAllocationRequest: (ticketId, body, eventId, agencyCode) => dispatch(modifyTicketAllocationRequest(ticketId, body, eventId, agencyCode)),
  getTotalReceivedAndAllocatedTickets: (agencyCode, eventId) => dispatch(totalReceivedAndAllocatedTickets(agencyCode, eventId)),
  updateBatchSharedRequest: (ticketData, subject, body, emailData) => dispatch(updateBatchSharedRequest(ticketData, subject, body, emailData)),
  sendEmailOnChangeStatus: (content) => dispatch(sendEmailOnChangeStatus(content)),
  sendEmailOnRejectClientTickets: (content) => dispatch(sendEmailOnRejectClientTickets(content)),
  getAgencyTotalTickets: (agencyId, eventId) => dispatch(getAgencyTotalTickets(agencyId, eventId)),
  saveChangeAgencyRequest: (data) => dispatch(saveChangeAgencyRequest(data)),
  //deleteRequestAgencyRequest: (ticketRequestId, agencyCode, agencyGuid, eventId) => dispatch(deleteRequestAgencyRequest(ticketRequestId, agencyCode, agencyGuid, eventId)),
  getChangeAgencyRequest: (ticketId) => dispatch(getChangeAgencyRequest(ticketId)),
  getRequestTotalTickets: (agencyCode, eventId) => dispatch(getRequestTotalTickets(agencyCode, eventId)),
  getEventCategoriesFn: user => dispatch(getEventCategories(user))
});

function afterFilter(newResult, newFilters) {
  if (Object.keys(newFilters).length) {
    newResult.unshift({
      name: '',
      email: '',
      TotalTickets: '',
      adultTickets: '',
      cTickets: '',
      comment: '',
      status: '',
      action: '',
      filterData: true
    });
  } else {
    if (newResult.length && newResult[0].filterData) {
      newResult.shift();
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(eventDetails));