import React, { useState } from 'react';
import { Row, Col, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DefaultInput } from './../../../UI/atoms/index';

export const GenerateTicketRequestModal = ({ data, onSubmitGenerateRequest, onCancel }) => {
    const adultTickets = data.evtech_numberofchaperones;
    const childrenTickets = data.evtech_numberofchildren;
    const totalTickets = adultTickets + childrenTickets;
    const [comment, setComment] = useState("");
    return (
        <Modal isOpen={true} className="event-popup modal-dialog-centered">
            <ModalHeader>Generate Experience Request</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <div className="form-group">
                            <Label for="numberAdults">Adult Experiences</Label>
                            <DefaultInput
                                id="numberAdults"
                                name="number"
                                type="number"
                                value={adultTickets}
                                placeholder="Adult Experiences"
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <Label for="numberChildren">Children Experiences</Label>
                            <DefaultInput
                                id="numberChildren"
                                name="Children Tickets"
                                type="number"
                                value={childrenTickets}
                                placeholder="Children Experiences"
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <Label for="totalTickets">Total Experiences</Label>
                            <DefaultInput
                                id="totalTickets"
                                name="Total Tickets"
                                type="number"
                                value={totalTickets}
                                placeholder="Total Experiences"
                                readOnly
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl="12">
                        <Label for="comment">Comments</Label>
                        <DefaultInput
                            name="comment"
                            type="text"
                            value={comment}
                            placeholder="add comments here (if any)"
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={(e) => onSubmitGenerateRequest({ comment })}>Submit</Button>{' '}
                <Button color="secondary" onClick={(e) => onCancel()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}